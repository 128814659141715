<template>
  <div
    class="credit-card-modal-container"
    :class="{ 'card-form-hidden': !value }"
    @click="closeModal"
  >
    <div class="modal-content" :class="{ 'modal-content-hidden': !value }">
      <div @click.stop class="modal-content-container">
        <div class="credit-card-modal-title">
          <span>Add credit card</span>
          <div 
            @click="closeModal"
            class="close-button-container"
          >
             <inline-svg 
              :src="require('@/assets/image/x.svg')" 
              fill="black"
            />
          </div>
        </div>
        <div class="card-form-padding">
          <div class="saved-payment-container" v-if="savedPayment">
            <div class="saved-payment-card">
              <img
                :src="getCardIconUrl(savedPayment.card.brand)"
                alt=""
                srcset=""
              />
              **** **** ****
              {{ savedPayment.card.last4 }}
            </div>
            <div class="saved-payment-button" @click="onChangeCardClicked">
              <span>Change card</span>
            </div>
          </div>
          <div
            v-show="!savedPayment"
            class="form-card-element"
            ref="cardElement"
          ></div>
          <div
            @click="onConfirmOrderClicked"
            class="form-confirm-order-button"
            :class="{
              disabled: shouldDisableButton,
            }"
          >
            <span>confirm order</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import * as Sentry from "@sentry/vue";

export default {
  props: {
    country: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    disableButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      stripePublishableKey: null,
      stripe: null,
      card: null,
      pm: null,
      stripe_processing: false,
      card_form_incomplete: true,
    };
  },
  methods: {
    getCardIconUrl(brand) {
      try {
        brand = brand.replace(/\s/g, "");
        brand = brand.toLowerCase();
        const img = require("../../assets/image/credit_cards/" +
          brand +
          ".svg");
        return img;
      } catch (error) {
        Sentry.captureMessage("Default card icon was returned: "+brand);
        const img = require("../../assets/image/credit_cards/default.svg");
        return img;
      }
    },
    initStripeCardForm() {
      this.stripe = Stripe(this.stripePublishableKey);
      let elements = this.stripe.elements();
      this.card = elements.create("card", { hidePostalCode: true });
      this.card.on("change", this.cardChangeHandler);
      this.card.mount(this.$refs.cardElement);
    },
    closeModal() {
      this.$emit("input", false);
    },
    onConfirmOrderClicked() {
      /* I button is disabled, ignore */
      if (this.shouldDisableButton) {
        return;
      }
      /* If there is a saved payment method, just send that */
      if (this.savedPayment != null) {
        // console.log("sending saved payment method");
        this.$emit("paymentmethod", this.savedPayment);
        this.$emit("input", false);
        return;
      }
      this.stripe_processing = true;
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.card,
        })
        .then((res) => {
          this.stripe_processing = false;
          if (res.error) {
            // TODO: display error below card form element similar to inputs
            this.$notify({ group: "error", text: res.error.message });

            // console.log(res.error);
            return;
          }
          const pm = res.paymentMethod;
          if (pm.id) {
            /* Save */
            this.pm = pm;
            this.$emit("paymentmethod", pm);
            this.$emit("input", false);
          }
        })
        .catch((err) => {
          this.stripe_processing = false;
          throw err;
        });
    },
    cardChangeHandler(event) {
      if (!event.complete) {
        this.card_form_incomplete = true;
      } else {
        this.card_form_incomplete = false;
      }
    },
    onChangeCardClicked() {
      this.$store.dispatch("savePaymentMethod", null);
    },
  },
  watch: {
    stripePublishableKey: {
      handler(val) {
        if (val) {
          this.initStripeCardForm();
        }
      },
    },
    value: {
      handler(modal_shown) {
        if (modal_shown) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      },
    },
    stripe_processing: {
      handler(val) {
        /* Use this to tell parent that this component is in loading state */
        this.$emit("stripeLoading", val);
      },
    },
  },
  computed: {
    savedPayment() {
      return this.$store.state.saved_payment_method;
    },
    shouldDisableButton() {
      if (this.savedPayment) {
        return false;
      }
      return (
        this.disableButton ||
        this.stripe_processing ||
        this.card_form_incomplete
      );
    },
  },
  apollo: {
    stripePublishableKey: {
      query: gql`
        query stripePublishableKey($serviceCountry: ServiceCountryEnum!) {
          stripePublishableKey(serviceCountry: $serviceCountry)
        }
      `,
      variables() {
        return {
          serviceCountry: this.country,
        };
      },
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.credit-card-modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  visibility: visible;
  transition: all 300ms ease-in-out;
  // transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out;
}

.card-form-hidden {
  visibility: hidden;
  background-color: rgb(0, 0, 0, 0);
}

.modal-content-container {
  background: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0px 0px;
}
.credit-card-modal-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 8px 0px 16px;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
}
.card-form-padding {
  padding: 8px;
}
.form-card-element {
  padding: 16px;
  border: 1px solid #dce1e5;
  box-sizing: border-box;
  margin: 8px 8px 16px 8px;
}
.form-confirm-order-button {
  background: #0890F1;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background 200ms ease-in-out;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
  }
  &.disabled {
    background: #a6a6a6;
  }
}
.modal-content {
  visibility: visible;
  transition: all 300ms ease-in-out;
  // transition: transform 300ms ease-in-out, visibility 300ms linear;
}
.modal-content-hidden {
  transform: translate(0, 100%);
  visibility: hidden;
}
.close-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  padding-bottom: 10px;
  padding-right: 10px;
  color: #dce1e5;

  span {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding-right: 7px;
  }
  img {
    cursor: pointer;
  }
}
.saved-payment-container {
  background: #f6f7f8;
  border-radius: 4px;
  margin: 8px 8px 24px 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .saved-payment-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 15px;
      margin-right: 8px;
    }
  }
  .saved-payment-button {
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #0890F1;
    }
  }
}
</style>
