<template>
  <page-container page="error">
    <div class="error-screen-container">
      <img class="error-image" src="@/assets/image/error/newError.svg" alt="" />
      <div class="error-header">
        <span class="header-h1">Scan the QR tag to start</span>
        <span class="error-subtitle body-base">
          Each order has a unique code to ensure your order is processed
          appropriately.
        </span>
        <span class="error-subtitle body-base">
          Start your journey by scanning a new, unused QR tag.
        </span>
      </div>
      <div class="error-instructions">
        <span class="header-h4">How to book?</span>
        <div class="instructions">
          <span class="step">
            <span class="circleWrapper">
              <img class="circle-icon" src="@/assets/image/circle.svg" alt="" />
              <span class="number">1.</span>
            </span>
            <span class="body-small">
              Go to the Laundryheap collection point in your building
            </span>
          </span>
          <span class="step">
            <span class="circleWrapper">
              <img class="circle-icon" src="@/assets/image/circle.svg" alt="" />
              <span class="number">2.</span>
            </span>
            <span class="body-small">
              Collect a bag and a QR tag
            </span>
          </span>
          <span class="step">
            <span class="circleWrapper">
              <img class="circle-icon" src="@/assets/image/circle.svg" alt="" />
              <span class="number">3.</span>
            </span>
            <span class="body-small">
              Open your camera app on your phone and scan the QR tag
            </span>
          </span>
          <span class="step">
            <span class="circleWrapper">
              <img class="circle-icon" src="@/assets/image/circle.svg" alt="" />
              <span class="number">4.</span>
            </span>
            <span class="body-small">
              You will be redirected to our website where you can complete your booking
            </span>
          </span>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import PageContainer from "../../components/PageContainer.vue";
export default {
  components: { PageContainer },
};
</script>

<style lang="scss" scoped>
.error-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px;
  min-height: min-content;
  .error-image {
    display: block;
    height: 96px;
  }
  /* span {
    display: block;
    text-align: center;
  } */
  .error-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 40px;
    .error-subtitle {
      margin-top: 8px;
    }
  }
  .error-instructions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.instructions {
  margin-top: 10px;
}
.step {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .circleWrapper {
    position: relative;

    > img {
      width: 60px;
      height: 60px;
    }

    > .number {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #202B93;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > .body-small {
    margin-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
