<template>
  <div
    class="modal-container"
    id="bottom-modal-container"
    @click="closeModal"
    :style="{ display: display, opacity: opacity }"
  >
    <div class="modal-content" ref="modalContentContainer">
      <div 
        class="close-button-container"
        @click="closeModal"
      > 
        <inline-svg 
          :src="require('@/assets/image/x.svg')" 
          :fill="lightMode ? 'white' : 'black'"
        />
      </div>
      <div class="modal-slot-wrapper" @click.stop>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    lightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      display: "none",
      opacity: 0,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.handleDisplay(val);
      },
    },
  },
  methods: {
    handleDisplay(val) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
        /* If modal has to be shown, we first need to unset display:none, and then add the active class, so animation works */
        this.display = "flex";
        setTimeout(() => {
          this.opacity = 1;
        }, 1);
      } else {
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
        this.opacity = 0;
        setTimeout(() => {
          this.display = "none";
        }, 350);
      }
    },
    closeModal() {
      this.show_content = false;
      this.$emit("input", false);
    },
    keyCloseModal({ key }) {
      if (key === "Escape") {
        this.closeModal();
      }
    },
  },
  mounted() {
    this.handleDisplay(this.value);
    document.addEventListener("keyup", this.keyCloseModal);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.keyCloseModal);
    document.documentElement.style.overflow = "auto";
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  z-index: 1;

  opacity: 0;
  background: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transition: all 300ms ease-in-out;

  &.modal-active {
    opacity: 1;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}

.modal-slot-wrapper {
  display: flex;
  flex-direction: column;
}

.close-button-container {
  position: absolute;
  right: 0;
  padding: 16px;
}

</style>