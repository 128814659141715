<template>
  <div
    ref="pageContainer"
    id="page-container"
    :class="{ 'full-height': expanded }"
  >
    <div v-if="milis_left > 0" id="countdown-timer">
      <span class="countdown-time count">{{ milis_left | milisToHHMM }}</span>
      <span>Drop your bag to get it back tomorrow</span>
    </div>
    <div id="container-header">
      <img
        :src="logoUrl"
        alt="Laundryheap"
      />
    </div>
    <div class="hr-light header"></div>

    <div id="container-content" :class="{ expanded: expanded }">
      <div id="content-header-container">
        <div id="content-title" class="header-title" v-if="headerText">
          {{ headerText }}
        </div>
        <div id="content-header-append">
          <slot name="append-header"></slot>
        </div>
      </div>
      <slot></slot>
    </div>

    <div class="hr-light"></div>
    <div v-if="!hideHelp" id="container-footer">
      <div @click="helpClicked" class="help-button">
        <span>{{
          page == "payment" ? "How much will I pay?" : "Need help?"
        }}</span>
      </div>
    </div>

    <div id="page-container-bottom-spacer" class="bottom-spacer"></div>

    <div id="bottom-slot">
      <slot name="bottom-floating"></slot>
    </div>

    <!-- Order creation error -->
    <notifications
      group="error"
      position="top right"
      width="100%"
      :duration="5000"
    >
      <template slot="body" slot-scope="{ close, item }">
        <span class="lh-alert">
          <span>
            <img
              @click="close"
              src="@/assets/image/error_icon.svg"
            />
            <span>{{ item.text }}</span>
          </span>
        </span>
      </template>
    </notifications>
    <!-- Loading animation -->
    <spinner-full-screen :shown="loading"></spinner-full-screen>

    <bottom-modal v-model="show_help_modal">
      <need-help-screen :page="page" :invoiceManually="invoiceManually" > </need-help-screen>
    </bottom-modal>
  </div>
</template>

<script>
import BottomModal from "./lh-ui/BottomModal.vue";
import NeedHelpScreen from "./NeedHelpScreen.vue";
import SpinnerFullScreen from "./lh-ui/SpinnerFullScreen.vue";
import logo from '../assets/image/common/header_logo.svg';
import {mapGetters} from "vuex";

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
    },
    expanded: {
      type: Boolean,
    },
    hideHelp: {
      type: Boolean,
      default: false,
    },
  },
  components: { BottomModal, NeedHelpScreen, SpinnerFullScreen },
  data() {
    return {
      show_help_modal: false,
      milis_left: 0,
      destroyed: false,
    };
  },
  methods: {
    helpClicked() {
      if (this.page == "confirmation" || this.page == "error") {
        this.$store.dispatch('openSupport');
        return;
      }
      if (this.page == "payment") {
        this.$emit("onEstimatesClicked");
        return;
      }
      this.show_help_modal = true;
    },
    /**
     * Set bottom spacer height to match height of bottom slot, effectively adding a bottom margin to page content
     */
    bottomSlotResized() {
      const bottomSlot = document.getElementById("bottom-slot");
      const bottomSpacer = document.getElementById(
        "page-container-bottom-spacer"
      );
      const bottomSlotHeight = bottomSlot.clientHeight;
      bottomSpacer.style.minHeight = `${bottomSlotHeight}px`;
    },
    handleExpanded(expanded) {
      if (expanded) {
        document.documentElement.style.height = "100%";
        document.body.style.height = "100%";
      } else {
        document.documentElement.style.height = "";
        document.body.style.height = "";
      }
    },
    initTimer() {
      // console.log("starting timer");
      if (!this.dropBy) {
        return;
      }
      const dateNow = Date.now();
      const dropDate = new Date(this.dropBy);
      const diff = dropDate - dateNow;
      if (diff < 90 * 60 * 1000) {
        // console.log("timer started");

        /* timer should start */
        const updateCountdown = () => {
          // console.log("computing diff");
          let now = Date.now();
          let difference = dropDate - now;
          this.milis_left = difference;
          if (difference > 0 && !this.destroyed) {
            setTimeout(updateCountdown, 1000);
          }
        };
        updateCountdown();
      } else {
        // console.log("more than 90min");
      }
    },
  },
  filters: {
    milisToHHMM(milis) {
      let seconds = Math.round(milis / 1000);
      let minutes = Math.floor(seconds / 60);
      let minSeconds = Math.round(seconds % 60);
      return `${minutes
        .toString()
        .padStart(2, "0")}:${minSeconds.toString().padStart(2, "0")}`;
    },
  },
  watch: {
    expanded: {
      handler(val) {
        this.handleExpanded(val);
      },
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    dropBy() {
      return this.$store.getters.dropBy;
    },
    location() {
      return this.$store.state?.location;
    },
    logoUrl() {
      return this.location?.logo ? this.location?.logo : logo;
    },
    ...mapGetters([
      "invoiceManually",
    ]),
  },
  mounted() {
    if (this.page == "services" || this.page == "payment") {
      /* Try to start countdown right away */
      this.initTimer();

      /* Check if dropoff time is less than 90min every 5 seconds */
      const checkTime = () => {
        if (!this.dropBy) {
          return;
        }
        // console.log("checking time");
        if (this.milis_left != 0) {
          // console.log("exiting time check");
          return;
        }
        const dateNow = Date.now();
        const dropDate = new Date(this.dropBy);
        const diff = dropDate - dateNow;
        if (diff < 90 * 60 * 1000) {
          this.milis_left = diff;
          this.initTimer();
        } else {
          setTimeout(checkTime, 5000);
        }
      };
      checkTime();
    }

    this.handleExpanded(this.expanded);
    this.bottomSlotResized();
    /* Create a bottom slot size observer, so if bottom slot content changes, the page padding changes accordingly */
    const bottomSlot = document.getElementById("bottom-slot");
    try {
      new ResizeObserver(this.bottomSlotResized).observe(bottomSlot);
    } catch (error) {
      // console.log("resize observer not available");
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  },
};
</script>

<style lang="scss" scoped>
#countdown-timer {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFE5AE;
  padding: 10px;
  

  > span {
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #585c62;
    margin-right: 5px;
  }

  .count {
    font-weight: bold;
  }
}
#container-header {
  padding: 0px 16px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  img {
    display: block;
    height: 26px;
    max-width: 100%;
  }
}

#container-header > img {
  height: 25px;
}
#page-container {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  flex: 1;
  &.full-height {
    height: 100%;
  }
}
.page-content-wrapper {
  flex: 1;
}
#container-content {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 0px 16px;
  #content-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 16px;
    #content-title {
      margin-top: 32px;
    }
    #content-header-append {
      align-self: flex-end;
      height: min-content;
    }
  }
  &.expanded {
    flex: 1;
  }
}
#container-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px 16px;
  .help-button {
    margin-top: 24px;
    margin-bottom: 32px;
    border: 1px solid #d8dcdf;
    border-radius: 100px;
    cursor: pointer;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      color: #202b93;
    }
  }
}
#bottom-slot {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
