<template>
  <div>
    <div
      class="phone-field-container"
      :class="{
        'phone-field-container-focused': focused,
        'phone-field-container-error': has_error,
      }"
    >
      <div @click="onCountryButtonClicked" class="phone-country-select-button">
        <img
          v-if="selected_country"
          class="img-flag"
          :src="selected_country.flag"
          alt=""
        />
        <img class="img-arrow" src="@/assets/image/dropdown_arrow.svg" alt="" />
        <div class="separator"></div>
      </div>

      <div class="phone-field">
        <div
          class="phone-input-container"
          :class="{
            'phone-input-container-error': has_error,
          }"
        >
          <span
            class="phone-placeholder"
            :class="{ 'placeholder-visible': phone_number || focused }"
            >{{ placeholder }}</span
          >
          <div class="phone-number-container">
            <span v-if="selected_country" class="country-code-prepend">{{
              selected_country.dialCode
            }}</span>
            <input
              @focus="onFieldFocus"
              @blur="onFieldUnfocus"
              :placeholder="(focused ? 'Enter your number' : 'Phone number' )"
              class="phone-field-input"
              v-model="phone_number"
              type="tel"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="field-notes" :class="{ 'field-notes-error': has_error }">
      <span>{{ error_message || notes }}</span>
    </div>

    <bottom-modal v-model="show_country_select">
      <div class="phone-country-select-modal-content">
        <div
          v-for="c in countries"
          :key="c.isoCode"
          @click.stop="countrySelected(c)"
          class="phone-country-select-item"
        >
          <img :src="c.flag" alt="" />
          <span>
            {{ c.name }}
          </span>
          <span>({{ c.dialCode }})</span>
        </div>
      </div>
    </bottom-modal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import BottomModal from "./BottomModal.vue";

export default {
  props: {
    value: {
      type: String,
    },
    notes: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  components: { BottomModal },
  data() {
    return {
      countries: [],
      selected_country: null,
      phone_number: null,
      show_country_select: false,
      focused: false,
      has_error: false,
      error_message: null,
    };
  },
  apollo: {
    countries: {
      query: gql`
        query {
          countries {
            name
            isoCode
            dialCode
            flag
          }
        }
      `,
    },
  },
  computed: {
    selectedCountry() {
      return this.$store.state.location.serviceCountry.code;
    },
    wholePhoneNumber() {
      if (!this.phone_number || !this.selected_country) {
        return "";
      }
      return `${this.selected_country.dialCode}${this.phone_number}`;
    },
  },
  watch: {
    phone_number: {
      handler(val) {
        const validation = this.validatePhoneForE164(this.wholePhoneNumber);
        this.$emit("validated", validation);
        if (validation) {
          this.error_message = null;
          this.has_error = false;
        }
        if (!val) {
          this.$emit("input", "");
        } else {
          if (this.selected_country) {
            this.$emit("input", `${this.selected_country.dialCode} ${val}`);
          } else {
            this.$emit("input", val);
          }
        }
      },
      value: {
        handler(val) {
          this.populatePhoneNumber();
        },
      },
    },
    countries: {
      handler(val) {
        if (val) {
          const cntry = val.find((el) => this.selectedCountry == el.isoCode);
          this.selected_country = cntry;
        }
      },
    },
  },
  methods: {
    onCountryButtonClicked() {
      this.show_country_select = true;
    },
    countrySelected(country) {
      this.selected_country = country;
      this.show_country_select = false;
    },
    onFieldFocus() {
      this.focused = true;
    },
    onFieldUnfocus() {
      const validation = this.validatePhoneForE164(this.wholePhoneNumber);
      if (validation) {
        this.has_error = false;
        this.error_message = null;
      } else {
        this.error_message = "Phone number is in the wrong format";
        this.has_error = true;
      }
      this.focused = false;
    },
    validatePhoneForE164(phoneNumber) {
      if (!phoneNumber) {
        return true;
      }
      const regEx = /^\+[1-9]\d{10,14}$/;
      return regEx.test(phoneNumber);
    },
    populatePhoneNumber() {
      if (this.value) {
        // console.log(this.value);
        this.phone_number = this.value.split(/ (.+)/)[1];
      }
    },
  },
  mounted() {
    this.populatePhoneNumber();
  },
};
</script>

<style lang="scss" scoped>
.phone-field-container {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #dce1e5;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 0 16px;
  align-items: center;

  height: 60px;
  width: 100%;

  overflow: hidden;
}
.phone-country-select-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  .img-flag {
    height: 16px;
  }
  .img-arrow {
    margin-left: 10px;
  }
  .separator {
    border-right: 1px solid #dce1e5;
    margin-right: 10px;
    margin-left: 10px;
    height: 20px;
  }
}
.phone-field {
  flex: 1;
}
.phone-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.phone-number-container {
  display: flex;
  flex-direction: row;
}
.phone-field-container-focused {
  border: 1px solid #0890F1;
}
.phone-field-container-error {
  border: 1px solid #ED738C;
  .phone-placeholder {
    color: #ED738C;
  }
}
.country-code-prepend {
  margin-right: 8px;
}
.phone-field-input {
  box-sizing: border-box;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  flex: 1;
  max-width: 100%;
}
.phone-placeholder {
  opacity: 0;
  display: none;
  transition: opacity 500ms ease-in-out;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 1px;
}
.placeholder-visible {
  opacity: 1;
  display: inline-block;
}
.field-notes {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #a6a6a6;
  margin-left: 16px;
}
.field-notes-error {
  color: #ED738C;
}
.phone-country-select-modal-content {
  height: 50vh;
  overflow: auto;
  background: #ffffff;
  /* Shadow box (modal) */

  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0px 0px;
  padding: 16px;
}
.phone-country-select-item {
  padding: 5px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  img {
    height: 20px;
    margin-right: 16px;
  }
  span {
    margin-right: 8px;
  }
}
</style>