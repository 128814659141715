<template>
  <div v-if="priceService" class="item-based-estimate-container">
    <div class="estimate-fact-container">
      <div class="estimate-fact">
        <img src="@/assets/image/checkmark.svg" alt="" />
        <span>Pay only for what you send us</span>
      </div>
      <div class="estimate-fact">
        <img src="@/assets/image/checkmark.svg" alt="" />
        <span>No minimum order or hidden costs</span>
      </div>
    </div>
    <div class="popular-items-container">
      <div v-for="i in popularItems" :key="i.title" class="popular-item-row">
        <span class="item-name">{{ i.title }}</span>
        <span class="separator"></span>
        <span class="item-price"
          >{{ currencySymbol }}{{ i.price | formatPrice }}</span
        >
      </div>
    </div>

    <price-list-simple
      :currency="currencySymbol"
      nopopular
      v-if="show_full_pricelist"
      :priceService="priceService"
    ></price-list-simple>
    <div class="see-pricelist-button-container">
      <div
        class="see-pricelist-button"
        @click="show_full_pricelist = !show_full_pricelist"
      >
        <img v-show="!show_full_pricelist" src="@/assets/image/common/blue_arrow_right.svg" alt="" />
        <span>{{ show_full_pricelist ? "Hide" : "See" }} the full price list</span>
      </div>
    </div>
  </div>
</template>

<script>
import PriceListSimple from "./PriceListSimple";
export default {
  props: {
    priceService: {
      type: Object,
    },
  },
  components: {
    PriceListSimple,
  },
  data() {
    return {
      show_full_pricelist: false,
    };
  },
  computed: {
    currencySymbol() {
      return this.$store.state.location.serviceCountry.currency;
    },
    popularItems() {
      if (this.priceService.priceSections) {
        let popularItems = [];
        for (const ps of this.priceService.priceSections) {
          for (const pg of ps.priceGroups) {
            for (const pi of pg.priceItems) {
              if (pi.popularPriceGroup) {
                popularItems.push(pi);
              }
            }
          }
        }
        return popularItems;
      } else {
        // console.log("empty");
        return [];
      }
    },
  },
  filters: {
    formatPrice: function (value) {
      const price = parseFloat(value);
      return price.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.estimate-fact-container {
  padding: 8px;
  border-radius: 4px;
  .estimate-fact {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 6px;
      display: block;
      height: 16px;
      width: 16px;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #2e2e3a;
    }
  }
  .estimate-fact:not(:last-child) {
    margin-bottom: 8px;
  }
}
.popular-items-container {
  padding: 16px 0px;
  .popular-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-name {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
    }
    .separator {
      flex: 1;
      border-bottom: 2px dotted #2e2e3a;
      margin-top: 9px;
      margin-left: 4px;
      margin-right: 4px;
      // margin: 0 6px;
    }
    .item-price {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .popular-item-row:not(:last-child) {
    margin-bottom: 16px;
  }
}
.see-pricelist-button-container {
  padding: 0 0 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .see-pricelist-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 14px;
      display: block;
      margin-right: 2px;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #0890F1;
    }
  }
}
</style>