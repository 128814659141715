<template>
  <page-container
    page="payment"
    headerText="3 - Contact"
    @onEstimatesClicked="show_estimates_modal = true"
    :hideHelp="invoiceManually"
  >
    <div class="payment-screen-container">
      <div class="email-field-wrapper">
        <email-field
          v-model="email"
          :emailDomain="emailDomain"
          notes="We will use it to send order confirmation and invoice."
          @validated="onEmailValidated"
        ></email-field>
      </div>
      <div class="phone-field-wrapper">
        <phone-input-new
          v-model="telephone"
          notes="We will use it only to send your order updates."
          @validated="onPhoneValidated"
          @countryUpdate="onCountryUpdate"
        ></phone-input-new>
      </div>
      <div v-if="selectedLocation.roomNumberRequired" class="roomNumber-field-wrapper">
        <room-number-field
          v-model="roomNumber"
          @validated="onRoomNumberValidated"
        />
      </div>
    </div>
    <div class="payment-screen-filler"></div>
    <div class="payment-notes-container">
      <payment-note-row :text="dropBy">
        Drop by {{ dropBy | timeString }} and have them back by
        {{ collectOn | timeString }}
      </payment-note-row>
      <template v-if="!invoiceManually">
        <payment-note-row>
          Your card will be charged after we’ve cleaned your items
        </payment-note-row>
        <payment-note-row> No minimum order or hidden costs </payment-note-row>
      </template>
    </div>

    <bottom-modal key="estimate-modal" v-model="show_estimates_modal">
      <estimates @ok="show_estimates_modal = false"></estimates>
    </bottom-modal>

    <bottom-modal key="missed-round-modal" v-model="show_missed_round_modal">
      <missed-today-prompt
        @proceed="proceedOrderSkipTimeslot"
        @cancel="cancelOrder"
        :location="selectedLocation"
      >
      </missed-today-prompt>
    </bottom-modal>

    <credit-card-form-modal
      v-model="show_card_form"
      :country="selectedCountry.code"
      @paymentmethod="onNormalPaymentMethod"
      :disableButton="disablePayment || order_creating"
      @stripeLoading="handleStripeLoadingState"
    >
    </credit-card-form-modal>

    <!-- Phone taken modal -->
    <bottom-modal
      key="phone-taken-modal"
      :value="!!phoneTakenData"
      @input="
        value => {
          if (!value) {
            phoneTakenData = null;
          }
        }
      "
    >
      <PhoneTakenPrompt :data="phoneTakenData" @ok="phoneTakenData = null" />
    </bottom-modal>

    <span class="terms">
      By continuing you agree to our <a target="_blank" :href="termsUrl">Terms</a> and <a target="_blank" :href="privacyUrl">Privacy Policy</a>
    </span>

    <template v-if="invoiceManually" v-slot:bottom-floating>
      <bottom-button text="Place order" :disabled="disablePayment" @clicked="placeManuallyInvoicedOrder"></bottom-button>
    </template>
    <template v-else v-slot:bottom-floating>
      <payment-button-group
        :nativeMethods="native_methods"
        :disabled="disablePayment || order_creating"
        @clicked="onPayNativeClicked"
        @clickedAlternative="showCreditCardForm"
      >
      </payment-button-group>
    </template>
  </page-container>
</template>

<script>
import PageContainer from "../components/PageContainer.vue";
import BottomButton from "../components/lh-ui/BottomButton.vue";
import PhoneInput from "../components/lh-ui/PhoneInput.vue";
import BottomModal from "../components/lh-ui/BottomModal.vue";
import PhoneInputNew from "../components/lh-ui/PhoneInputNew.vue";
import EmailField from "../components/lh-ui/EmailField.vue";
import RoomNumberField from "../components/lh-ui/RoomNumberField.vue";
import PaymentNoteRow from "../components/payment/PaymentNoteRow.vue";
import Estimates from "../components/payment/Estimates.vue";
import CreditCardFormModal from "../components/payment/CreditCardFormModal.vue";
import PaymentButtonGroup from "../components/payment/PaymentButtonGroup.vue";
import SpinnerFullScreen from "../components/lh-ui/SpinnerFullScreen.vue";
import MissedTodayPrompt from "../components/payment/MissedTodayPrompt.vue";
import PhoneTakenPrompt from '@/components/lh-ui/PhoneTakenPrompt.vue';
import gql from 'graphql-tag';


export default {
  components: {
    PhoneTakenPrompt,
    PageContainer,
    BottomButton,
    PhoneInput,
    EmailField,
    PaymentNoteRow,
    CreditCardFormModal,
    PaymentButtonGroup,
    PhoneInputNew,
    SpinnerFullScreen,
    BottomModal,
    Estimates,
    MissedTodayPrompt,
    RoomNumberField,
  },
  data() {
    return {
      /* Order data */
      telephone: null,
      country: null,
      email: null,
      roomNumber: null,
      /* Validation stuff */
      phone_valid: null,
      email_valid: null,
      roomNumber_valid: null,
      /* Stripe stuff */
      stripe: null,
      card: null,
      stripePublishableKey: null,
      paymentRequest: null,
      native_methods: null,

      show_card_form: false,
      order_creating: false,
      stripe_loading: false,
      show_estimates_modal: false,
      /* Missed round */
      show_missed_round_modal: false,
      skip_timeslot_validation: false,
      /* Phone taken */
      phoneTakenData: null,
    };
  },
  computed: {
    selectedCountry() {
      return this.$store.state.location.serviceCountry;
    },
    selectedLocation() {
      return this.$store.state.location;
    },
    dropBy() {
      return this.$store.getters.dropBy;
    },
    collectOn() {
      return this.$store.getters.collectAfter;
    },
    order() {
      return this.$store.getters.lightOrder;
    },
    loading() {
      return this.$store.state.loading;
    },
    disablePayment() {
      return (
        !this.email || !this.email_valid || !this.telephone || !this.phone_valid || (this.selectedLocation.roomNumberRequired && (!this.roomNumber || !this.roomNumber_valid))
      );
    },
    invoiceManually() {
      return this.$store.getters.invoiceManually;
    },
    emailDomain() {
      return this.$store.getters.emailDomain;
    },
    termsUrl() {
      return `${this.selectedLocation.serviceCountry.url}/terms`
    },
    privacyUrl() {
      return `${this.selectedLocation.serviceCountry.url}/privacy`
    }
  },
  apollo: {
    stripePublishableKey: {
      query: gql`
        query stripePublishableKey($serviceCountry: ServiceCountryEnum!) {
          stripePublishableKey(serviceCountry: $serviceCountry)
        }
      `,
      variables() {
        return {
          serviceCountry: this.selectedCountry.code,
        };
      },
    },
  },
  watch: {
    stripePublishableKey: {
      handler(val) {
        if (val) {
          this.initPaymentRequest();
        }
      },
    },
    email: {
      handler(newEmail) {
        this.$store.dispatch("saveEmail", newEmail);
      }
    },
    telephone: {
      handler(newTelephone) {
        this.$store.dispatch("saveTelephone", newTelephone);
      }
    },
    roomNumber: {
      handler(newRoomNumber) {
        this.$store.dispatch("saveRoomNumber", newRoomNumber);
      }
    }
  },
  methods: {
    placeManuallyInvoicedOrder() {
      this.createOrderOnBackend();
    },
    cancelOrder() {
      this.show_missed_round_modal = false;
      // TODO: Cancel order
      // console.log("cancel order");
    },
    proceedOrderSkipTimeslot() {
      this.show_missed_round_modal = false;
      this.skip_timeslot_validation = true;
      this.createOrderOnBackend();
    },
    /**
     * Opens the modal that contains stripe credit card form
     */
    showCreditCardForm() {
      if (!this.disablePayment) {
        this.show_card_form = true;
      }
    },
    /**
     * Show the native payment UI
     */
    onPayNativeClicked() {
      this.paymentRequest.show();
    },
    /**
     * Init stripe stuff
     */
    initPaymentRequest() {
      this.stripe = Stripe(this.stripePublishableKey);

      this.paymentRequest = this.stripe.paymentRequest({
        country: this.selectedCountry.code,
        currency: this.selectedCountry.currencyIsoCode.toLowerCase(),
        total: {
          amount: 0,
          label: "Laundryheap",
          pending: true,
        },
      });

      /* Add a listener that triggers when stripe processes the payment method */
      this.paymentRequest.on("paymentmethod", this.onNativePaymentMethod);

      /* Check what methods the current browser supports */
      this.paymentRequest.canMakePayment().then((res) => {
        /* null if none */
        this.native_methods = res;
      });
    },
    /**
     * When native pay returns a payment method
     */
    async onNativePaymentMethod(ev) {
      if (ev.paymentMethod && ev.paymentMethod.id) {
        /* Save native payment method */
        this.$store.dispatch("saveNativePaymentMethod", ev.paymentMethod);
        ev.complete("success");

        /* Complete the order */
        this.$store.dispatch("saveEmail", this.email);
        this.$store.dispatch("saveTelephone", this.telephone);
        this.$store.dispatch("saveRoomNumber", this.roomNumber);
        this.$store.dispatch("saveCountry", this.country);
        this.createOrderOnBackend();
      } else {
        ev.complete("fail");
      }
    },
    /**
     * When a payment method id is retreived, save payment data and create an order
     */
    async onNormalPaymentMethod(pm) {
      if (pm.id) {
        this.$store.dispatch("savePaymentMethod", pm);
        this.$store.dispatch("saveEmail", this.email);
        this.$store.dispatch("saveTelephone", this.telephone);
        this.$store.dispatch("saveRoomNumber", this.roomNumber);
        this.$store.dispatch("saveCountry", this.country);

        this.createOrderOnBackend();
      }
    },
    handleStripeLoadingState(val) {
      if (val) {
        this.$store.dispatch("loading");
      } else {
        this.$store.dispatch("loaded");
      }
      this.stripe_loading = val;
    },
    async isTimeslotMissed() {
      await this.$store.dispatch("refreshLocation");
      if (this.$store.state.timeslot_missed && !this.skip_timeslot_validation) {
        this.show_missed_round_modal = true;
        return true
      }
      return false
    },
    /**
     * Check if email and phone combo is valid.
     * If phone is used with the entered email, it returns true.
     * If phone is used but with another email, it returns false.
     * If phone and email are new, it returns true.
     */
    async validateEmailAndPhone(phone, email) {
      const response = await this.$apollo
        .query({
          query: gql`
            query($phone: String!, $email: String!) {
              lightPhoneTaken(phone: $phone, email: $email) {
                taken
                email
              }
            }
          `,
          variables: {
            phone,
            email,
          },
        })
        .then(res => res.data.lightPhoneTaken);

      if (!response) {
        await this.$store.dispatch('showError', 'Something went wrong. Please try again.');
        return false;
      }

      if (response.taken) {
        this.phoneTakenData = response;
        return false;
      } else {
        return true;
      }
    },
    /**
     * Send order data to the backend, save returned data and push to confirmation screen
     */
    async createOrderOnBackend() {
      this.$store.dispatch("loading");

      if(await this.isTimeslotMissed()){
        this.$store.dispatch("loaded");
        return;
      }

      this.order_creating = true;

      const order = Object.assign({}, this.order);

      const areEmailAndPhoneValid = await this.validateEmailAndPhone(order.phone, order.email);

      if (!areEmailAndPhoneValid) {
        this.order_creating = false;
        await this.$store.dispatch('loaded');
        return;
      }

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation(
              $qrCode: String!
              $pmToken: String
              $serviceId: Int!
              $lightLocationId: Int!
              $email: String!
              $phone: String!
              $specialInstructions: String!
              $skipTimeslotValidation: Boolean!
              $roomNumber: String
            ) {
              createLightOrder(
                qrCode: $qrCode
                pmToken: $pmToken
                serviceId: $serviceId
                lightLocationId: $lightLocationId
                email: $email
                phone: $phone
                roomNumber: $roomNumber
                specialInstructions: $specialInstructions
                skipTimeslotValidation: $skipTimeslotValidation
              ) {
                id
                number
              }
            }
          `,
          variables: {
            qrCode: order.qrCode,
            pmToken: this.invoiceManually ? null : order.pmToken,
            serviceId: order.serviceId,
            lightLocationId: order.lightLocationId,
            email: order.email,
            roomNumber: this.selectedLocation.roomNumberRequired && order.roomNumber,
            phone: order.phone,
            specialInstructions: order.specialInstructions,
            skipTimeslotValidation: true,
          },
        })
        .then((res) => {
          // console.log("order created");
          // console.log(res);
          this.$store.dispatch("setReturnFlow", true);
          this.$store.dispatch("saveCreatedOrder", res.data.createLightOrder);
          this.order_creating = false;
          this.$store.dispatch("loaded");
          this.$router.push({ name: "Confirmation" });
        })
        .catch((error) => {
          // console.log("error when creating order");
          this.order_creating = false;
          this.$store.dispatch("loaded");
          this.handleOrderErrors(error);
        });
    },
    /**
     * Parse errors that backend returns when creating an order
     */
    handleOrderErrors(error) {
      // console.log("order error");
      if (error.graphQLErrors) {
        const errors = error.graphQLErrors;
        for (const err of errors) {
          if (err.short_code == "light_location_too_late") {
            // this.show_missed_round_modal = true;
            // console.log("You were too late for this day.");
          } else if (err.short_code == "light_tracking_code_used") {
            this.$notify({ group: "error", text: err.message });
            // console.log(err);
          } else {
            this.$notify({ group: "error", text: err.message });
          }
        }
      }
    },
    onEmailValidated(result) {
      this.email_valid = result;
    },
    onRoomNumberValidated(result) {
      this.roomNumber_valid = result;
    },
    onPhoneValidated(result) {
      this.phone_valid = result;
    },
    onCountryUpdate(country) {
      this.country = country;
    },
  },
  mounted() {
    /* Pre-populate the fields from store */
    this.email = this.$store.state.email;
    this.telephone = this.$store.state.telephone;
    this.roomNumber = this.$store.state.roomNumber;
    this.country = this.$store.state.country;
  },
  beforeRouteLeave(to, from, next) {
    /* Save information before leaving route */
    this.$store.dispatch("saveEmail", this.email);
    this.$store.dispatch("saveTelephone", this.telephone);
    this.$store.dispatch("saveRoomNumber", this.roomNumber);
    this.$store.dispatch("saveCountry", this.country);
    next();
  },
};
</script>

<style lang="scss" scoped>
.email-field-wrapper {
  padding-bottom: 10px;
}
.phone-field-wrapper {
  padding-bottom: 10px;
}
.payment-screen-container {
  display: flex;
  flex-direction: column;
}
.native-pay-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2e2e3a;
  opacity: 0.4;
  border-radius: 4px;
  padding: 12px 0;
  span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
    color: #ffffff;
  }
}
.payment-screen-filler {
  height: 140px;
}
@media only screen and (max-height: 650px) {
  .payment-screen-filler {
    height: 40px;
  }
}
.payment-notes-container {
  padding-bottom: 16px;
}
.card-element {
  background: #ffffff;
  /* base/4 */

  border: 1px solid #dce1e5;
  box-sizing: border-box;
  padding: 18px 16px 16px;
}
.confirm-order-button {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  background: #12d572;
  border-radius: 4px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.terms {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 10px;
  a {
    text-decoration: none;
    color: #0890F1;
  }
}
</style>
