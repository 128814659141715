<template>
  <div class="return-flow-container">
    <div class="return-flow-header">
      <span class="header-h3">Review order</span>
    </div>
    <div class="return-flow-content">
      <div class="return-flow-section">
        <location-box
          @changeClicked="onLocationChangeClicked"
          :location="selectedLocation"
        ></location-box>
      </div>
      <div class="return-flow-section">
        <contact-box
          @changeClicked="onContactChangeClicked"
          :email="email"
          :phone="phone"
          :roomNumber="roomNumber"
        >
        </contact-box>
      </div>
      <div v-if="!invoiceManually" class="return-flow-section">
        <span class="section-title header-h5">Payment</span>
        <saved-payment-box
          :paymentMethod="savedPaymentMethod"
          :native="isSavedPaymentNative"
          :nativeType="savedNativePaymentMethodType"
          @changeClicked="onPaymentChangeClicked"
        >
        </saved-payment-box>
      </div>
    </div>

    <bottom-modal v-model="show_contact_change_modal">
      <change-contact-form
        :email="email"
        :phone="phone"
        :roomNumber="roomNumber"
        :country="country"
        :selectedLocation="selectedLocation"
        @saveClicked="onSaveContactInfo"
      ></change-contact-form>
    </bottom-modal>

    <bottom-modal v-model="show_add_card_modal">
      <add-credit-card
        @paymentmethod="onNewPaymentMethodReceived"
        :country="conutryCode"
      ></add-credit-card>
    </bottom-modal>

    <div class="terms">
      By continuing you agree to our <a target="_blank" :href="termsUrl">Terms</a> and <a target="_blank" :href="privacyUrl">Privacy Policy</a>
    </div>

    <template v-if="invoiceManually">
      <BottomButton text="Confirm order" @clicked="manualCreateOrder" ></BottomButton>
    </template>
    <template v-else>
      <div v-if="isSavedPaymentNative" class="native-pay-button-container">
        <native-payment-button
          :country="selectedCountry"
          @paymentMethod="onNativePaymentMethodReceived"
        ></native-payment-button>
      </div>
      <bottom-double-button
        v-else
        mainText="Confirm order"
        @mainClicked="onConfirmOrderClicked"
      ></bottom-double-button>
    </template>
    <bottom-modal key="missed-round-modal" v-model="show_missed_round_modal">
      <missed-today-prompt
        @proceed="proceedOrderSkipTimeslot"
        @cancel="cancelOrder"
        :location="selectedLocation"
      >
      </missed-today-prompt>
    </bottom-modal>

    <!-- Phone taken modal -->
    <bottom-modal
      key="phone-taken-modal"
      :value="!!phoneTakenData"
      @input="
        value => {
          if (!value) {
            phoneTakenData = null;
          }
        }
      "
    >
      <PhoneTakenPrompt :data="phoneTakenData" @ok="phoneTakenData = null" />
    </bottom-modal>
  </div>
</template>

<script>
import BottomDoubleButton from "../lh-ui/BottomDoubleButton.vue";
import LocationBox from "./LocationBox.vue";
import ContactBox from "./ContactBox.vue";
import SavedPaymentBox from "./SavedPaymentBox.vue";
import BottomModal from "../lh-ui/BottomModal.vue";
import ChangeContactForm from "./ChangeContactForm.vue";
import AddCreditCard from "../lh-ui/AddCreditCard.vue";
import NativePaymentButton from "../lh-ui/NativePaymentButton.vue";
import MissedTodayPrompt from "../payment/MissedTodayPrompt.vue";
import BottomButton from "@/components/lh-ui/BottomButton.vue";
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import PhoneTakenPrompt from '@/components/lh-ui/PhoneTakenPrompt.vue';

export default {
  components: {
    PhoneTakenPrompt,
    BottomDoubleButton,
    LocationBox,
    ContactBox,
    SavedPaymentBox,
    BottomModal,
    ChangeContactForm,
    AddCreditCard,
    NativePaymentButton,
    MissedTodayPrompt,
    BottomButton
  },
  data() {
    return {
      show_contact_change_modal: false,
      show_add_card_modal: false,
      skip_timeslot_validation: false,
      show_missed_round_modal: false,
      /* Phone taken */
      phoneTakenData: null,
    };
  },
  methods: {
    manualCreateOrder() {
      this.createOrderOnBackend();
    },
    onConfirmOrderClicked() {
      this.createOrderOnBackend();
    },
    onLocationChangeClicked() {
      this.$store.dispatch("setReturnFlow", false);
      this.$router.push({ name: "Location" });
    },
    onContactChangeClicked() {
      this.show_contact_change_modal = true;
    },
    onPaymentChangeClicked() {
      this.show_add_card_modal = true;
    },
    onSaveContactInfo(info) {
      const email = info.email;
      const phone = info.phone;
      const roomNumber = info.roomNumber;
      const country = info.country;
      this.$store.dispatch("saveEmail", email);
      this.$store.dispatch("saveTelephone", phone);
      this.$store.dispatch("saveRoomNumber", roomNumber);
      this.$store.dispatch("saveCountry", country);
      this.show_contact_change_modal = false;
    },
    onNewPaymentMethodReceived(paymentMethod) {
      if (paymentMethod.id) {
        this.$store.dispatch("savePaymentMethod", paymentMethod);
        this.show_add_card_modal = false;
      } else {
        // console.log("no payment method??");
      }
    },
    async onNativePaymentMethodReceived(paymentMethod) {
      /* Create an order with the newly provided payment method */
      if (paymentMethod && paymentMethod.id) {
        /* Save to store */
        this.$store.dispatch("saveNativePaymentMethod", paymentMethod);
        /* Create an order normally */
        this.createOrderOnBackend();
      } else {
        // console.log("no payment method??");
      }
    },
    async isTimeslotMissed() {
      await this.$store.dispatch("refreshLocation");
      if (this.$store.state.timeslot_missed && !this.skip_timeslot_validation) {
        this.show_missed_round_modal = true;
        return true;
      }
      return false;
    },
    /**
     * Check if email and phone combo is valid.
     * If phone is used with the entered email, it returns true.
     * If phone is used but with another email, it returns false.
     * If phone and email are new, it returns true.
     */
    async validateEmailAndPhone(phone, email) {
      const response = await this.$apollo
        .query({
          query: gql`
            query($phone: String!, $email: String!) {
              lightPhoneTaken(phone: $phone, email: $email) {
                taken
                email
              }
            }
          `,
          variables: {
            phone,
            email,
          },
        })
        .then(res => res.data.lightPhoneTaken);

      if (!response) {
        await this.$store.dispatch('showError', 'Something went wrong. Please try again.');
        return false;
      }

      if (response.taken) {
        this.phoneTakenData = response;
        return false;
      } else {
        return true;
      }
    },
    async createOrderOnBackend() {
      this.$store.dispatch("loading");

      if(await this.isTimeslotMissed()){
        this.$store.dispatch("loaded");
        return;
      }

      const order = Object.assign({}, this.$store.getters.lightOrder);

      const areEmailAndPhoneValid = await this.validateEmailAndPhone(order.phone, order.email);

      if (!areEmailAndPhoneValid) {
        await this.$store.dispatch('loaded');
        return;
      }

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation(
              $qrCode: String!
              $pmToken: String
              $serviceId: Int!
              $lightLocationId: Int!
              $email: String!
              $phone: String!
              $roomNumber: String
              $specialInstructions: String!
              $skipTimeslotValidation: Boolean!
            ) {
              createLightOrder(
                qrCode: $qrCode
                pmToken: $pmToken
                serviceId: $serviceId
                lightLocationId: $lightLocationId
                email: $email
                phone: $phone
                roomNumber: $roomNumber
                specialInstructions: $specialInstructions
                skipTimeslotValidation: $skipTimeslotValidation
              ) {
                id
                number
              }
            }
          `,
          variables: {
            qrCode: order.qrCode,
            pmToken: this.invoiceManually ? null : order.pmToken,
            serviceId: order.serviceId,
            lightLocationId: order.lightLocationId,
            email: order.email,
            phone: order.phone,
            roomNumber: this.selectedLocation.roomNumberRequired && order.roomNumber,
            specialInstructions: order.specialInstructions,
            skipTimeslotValidation: true,
          },
        })
        .then((res) => {
          this.$store.dispatch("setReturnFlow", true);
          this.$store.dispatch("saveCreatedOrder", res.data.createLightOrder);

          this.$store.dispatch("loaded");
          this.$router.push({ name: "Confirmation" });
        })
        .catch((error) => {
          this.$store.dispatch("loaded");
          this.handleGraphQLErrors(error);
        });
    },
    handleGraphQLErrors(error) {
      if (error.graphQLErrors) {
        const errors = error.graphQLErrors;
        for (const err of errors) {
          if (err.short_code == "light_location_too_late") {
            // this.show_missed_round_modal = true;
          } else if (err.short_code == "light_tracking_code_used") {
            this.$notify({ group: "error", text: err.message });
          } else {
            this.$notify({ group: "error", text: err.message });
          }
        }
      }
    },
    cancelOrder() {
      this.show_missed_round_modal = false;
    },
    proceedOrderSkipTimeslot() {
      this.skip_timeslot_validation = true;
      this.show_missed_round_modal = false;
      this.createOrderOnBackend();
    },
  },
  computed: {
    selectedLocation() {
      return this.$store.state.location;
    },
    phone() {
      return this.$store.state.telephone;
    },
    roomNumber() {
      if(this.selectedLocation.roomNumberRequired){
        return this.$store.state.roomNumber;
      }
      return undefined;
    },
    email() {
      return this.$store.state.email;
    },
    conutryCode() {
      return this.$store.state.location.serviceCountry.code;
    },
    country() {
      return this.$store.state.country;
    },
    ...mapGetters([
      "savedNativePaymentMethod",
      "isSavedPaymentNative",
      "savedNativePaymentMethodType",
      "savedPaymentMethod",
      "selectedCountry",
      "invoiceManually",
      "emailDomain"
    ]),
    loading() {
      return this.$store.state.loading;
    },
    termsUrl() {
      return `${this.selectedLocation.serviceCountry.url}/terms`
    },
    privacyUrl() {
      return `${this.selectedLocation.serviceCountry.url}/privacy`
    }
  },
  mounted() {
    this.$store.dispatch("refreshLocation", true);
  },
};
</script>

<style lang="scss" scoped>
.return-flow-container {
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
}
.return-flow-header {
  padding: 18px 16px 14px 16px;
}
.return-flow-content {
  padding: 16px 16px 16px 16px;

  .return-flow-section:not(:last-child) {
    margin-bottom: 16px;
  }
}
.native-pay-button-container {
  padding: 8px;
}
.terms {
  margin: 0px 10px 10px 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  a {
    text-decoration: none;
    color: #0890F1;
  }
}
</style>
