<template>
  <div class="estimates-screen-container">
    <div class="estimates-screen-header">
      <span>How much will I pay?</span>
    </div>
    <div class="estimates-screen-body">
      <template v-if="serviceType == 'wash'">
        <wash-estimate :priceService="priceService"></wash-estimate>
      </template>
      <template v-if="serviceType == 'dry cleaning'">
        <item-based-estimate :priceService="priceService"></item-based-estimate>
      </template>
      <contact-us-box title="Any questions?" small></contact-us-box>
    </div>
    <div class="ok-big-button-container">
      <div class="ok-big-button" @click="okClicked">
        <span>ok</span>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUsBox from "../ContactUsBox.vue";
import WashEstimate from "../payment/WashEstimate";
import ItemBasedEstimate from "../payment/ItemBasedEstimate";
import gql from "graphql-tag";

export default {
  components: {
    ContactUsBox,
    WashEstimate,
    ItemBasedEstimate,
  },
  data() {
    return {
      priceServices: null,
    };
  },
  methods: {
    okClicked(){
      this.$emit("ok")
    }
  },
  computed: {
    serviceType() {
      return this.$store.state.service.lightTitle.toLowerCase();
    },
    countryCode() {
      return this.$store.state.location.serviceCountry.code;
    },
    priceService() {
      return this.priceServices?.find(
        (ps) => ps.title.toLowerCase() == this.serviceType
      );
    },
  },
  apollo: {
    priceServices: {
      query: gql`
        query priceServices($serviceCountry: ServiceCountryEnum!) {
          priceServices(serviceCountry: $serviceCountry) {
            title
            position
            priceSections {
              position
              title
              priceGroups {
                title
                priceItems {
                  title
                  price
                  popularPriceGroup
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          serviceCountry: this.countryCode,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.estimates-screen-container {
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
}
.estimates-screen-header {
  padding: 18px 17px 14px 17px;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2e2e3a;
  }
}
.estimates-screen-body {
  padding: 16px 16px 24px 16px;
}
.ok-big-button-container {
  padding: 0 8px 8px 8px;
  .ok-big-button {
    background: #0890F1;
    border-radius: 4px;
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
}
</style>