<template>
  <div class="contact-us-notice-container">
    <div v-if="small" class="contact-us-notice-box-small">
      <span class="text">{{ title || "Have other questions?" }}</span>
      <span class="button" @click="onContactUsClicked">Contact us</span>
    </div>
    <div v-else class="contact-us-notice-box">
      <div class="contact-us-notice-header">
        <span>
          <img src="@/assets/image/help_question_mark.svg" alt="" />
        </span>
        <span>
          <div class="contact-us-notice-header-title">
            {{ title || "Have other questions?" }}
          </div>
          <div class="contact-us-notice-header-subtitle">
            Our team will happily help you.
          </div>
        </span>
      </div>
      <div @click="onContactUsClicked" class="contact-us-notice-button">
        <span>Contact us</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    small: {
      type: Boolean,
    },
  },
  methods: {
    onContactUsClicked() {
      this.$store.dispatch('openSupport');
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us-notice-container {
  .contact-us-notice-box {
    padding: 16px;
    background: #f3fbff;
    border: 1px solid #dce1e5;
    box-sizing: border-box;
    .contact-us-notice-button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #0890F1;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px 0px;
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #0890F1;
      }
    }
    .contact-us-notice-header {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      span {
        margin-left: 16px;
        &:first-child {
          margin-left: 0;
        }
      }
      .contact-us-notice-header-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        color: #2e2e3a;
      }
      .contact-us-notice-header-subtitle {
        margin-top: 3px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #2e2e3a;
      }
    }
  }
  .contact-us-notice-box-small {
    height: 36px;
    background: #f6f7f8;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
    .text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #323b44;
    }
    .button {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #0890F1;
      cursor: pointer;
    }
  }
}
</style>
