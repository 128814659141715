<template>
  <div class="bottom-button-container">
    <!-- Show this if native payment is available -->
    <div v-if="nativePayMethod">
      <div
        @click="mainButtonClicked"
        class="bottom-button"
        :class="{
          disabled: disabled,
          'apple-pay': nativePayMethod === 'applePay',
          'google-pay': nativePayMethod === 'googlePay',
          'other-payment-method': nativePayMethod !== 'applePay' && nativePayMethod !== 'googlePay',
        }"
      >
        <span v-if="nativePayMethod == 'googlePay'" class="google-pay-button">
          Pay with
          <img src="@/assets/image/payment/google-pay.svg" />
        </span>
        <span v-else>{{ payMethodText }}</span>
      </div>
      <div @click="altButtonClicked" class="button-alternative" :class="{ disabled: disabled }">
        <span>or pay with card</span>
      </div>
    </div>
    <!-- Show this if native payment is not available -->
    <div v-if="!nativePayMethod">
      <div
        @click="altButtonClicked"
        class="bottom-button credit-card"
        :class="{ disabled: disabled }"
      >
        <span>Pay with card</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    nativeMethods: {
      /* {method: available(bool)} */
      type: Object,
      default: () => null,
    },
  },
  methods: {
    mainButtonClicked() {
      if (!this.disabled) {
        this.$emit('clicked');
      }
    },
    altButtonClicked() {
      if (!this.disabled) {
        this.$emit('clickedAlternative');
      }
    },
  },
  computed: {
    /**
     * Returns native payment method name or null, if none are available
     */
    nativePayMethod() {
      if (this.nativeMethods) {
        for (const prop in this.nativeMethods) {
          if (this.nativeMethods[prop] == true) {
            return prop;
          }
        }
      }
      return null;
    },
    /**
     * Get the text for native payment button
     */
    payMethodText() {
      if (this.nativePayMethod == 'applePay') {
        return 'Pay with Pay';
      } else if (this.nativePayMethod == 'googlePay') {
        return 'Pay with Google Pay';
      } else {
        Sentry.captureMessage('New native payment method encountered: ' + this.nativePayMethod);
        return `Pay with ${this.nativePayMethod}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-button-container {
  margin: 8px;
  .button-alternative {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2e2e3a;
    opacity: 1;
    text-align: center;
    padding: 24px 0 16px 0;
    cursor: pointer;
    transition: opacity 200ms ease-in-out;
    &.disabled {
      opacity: 0.4;
    }
  }
}
.bottom-button {
  height: 54px;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    color: #ffffff;
  }

  &.credit-card {
    background: #0890F1;
    transition: background 200ms ease-in-out;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &.disabled {
      background: #a6a6a6;
    }
  }
  &.apple-pay {
    background: #2e2e3a;
    font-family: -apple-system, 'BlinkMacSystemFont';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
    transition: opacity 200ms ease-in-out;
    &.disabled {
      opacity: 0.4;
    }
  }
  &.google-pay {
    background: #000000;
    padding: 12px;
    opacity: 1;

    transition: all 200ms ease-in-out;

    &.disabled {
      opacity: 0.4;
    }
  }
  &.other-payment-method {
    background: #0890F1;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
    transition: opacity 200ms ease-in-out;
    &.disabled {
      background: #a6a6a6;
    }
  }
}

.google-pay-button {
  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap;
  height: 100%;

  font: 400 13.333px Arial;
  font-size: 22px;

  img {
    display: inline-block;
    height: 100%;
    margin-left: 10px;
  }
}
</style>
