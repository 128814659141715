<template>
  <div class="phone-field-container">
    <div
      class="phone-field-input-container"
      :class="{ active: focused, error: show_error }"
    >
      <div class="country-picker-container">
        <div class="select-container">
          <select v-model="selected_country">
            <option
              v-for="country in countries"
              :key="country.isoCode"
              :value="country"
            >
              {{ country.name }} ({{ country.dialCode }})
            </option>
          </select>
        </div>
        <div class="country-picker-flag-container">
          <img
            class="country-picker-flag"
            :src="selected_country && selected_country.flag"
          />
        </div>
        <div class="country-picker-arrow-container">
          <img
            src="@/assets/image/dropdown_arrow.svg"
            class="country-picker-arrow"
          />
        </div>
        <div class="country-picker-separator"></div>
      </div>
      <div class="phone-input-container">
        <div
          class="phone-input-label"
          :class="{ error: show_error }"
          v-if="focused || phone_number"
        >
          Phone number
        </div>
        <div class="phone-input-number-group">
          <span class="prefix">{{
            selected_country && selected_country.dialCode
          }}</span>
          <input
            v-model="phone_number"
            :placeholder="focused ? 'Enter your number' : 'Phone number'"
            type="tel"
            @focus="onFieldFocused"
            @blur="onFieldUnfocused"
          />
        </div>
      </div>
    </div>
    <div v-if="!show_error" class="phone-field-notes-container">
      <span>{{ notes }}</span>
    </div>
    <div v-else class="phone-field-notes-container error">
      <span>Phone number is in the wrong format</span>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: {
    value: {
      type: String,
    },
    notes: {
      type: String,
    },
  },
  data() {
    return {
      phone_number: null,
      show_error: false,
      focused: false,
      countries: [],
      selected_country: null,
    };
  },
  apollo: {
    countries: {
      query: gql`
        query {
          countries {
            name
            isoCode
            dialCode
            flag
          }
        }
      `,
      update: function (data) {
        if (!this.selected_country) {
          this.selected_country = data.countries.find(
            (el) => el.isoCode === (this.storedPhoneCountry?.isoCode || this.storedCountry?.code)
          );
        }

        return data.countries;
      },
    },
  },
  methods: {
    populateField() {
      if (this.countries.length > 0 && this.value) {
        const number = this.value;
        if (!this.selected_country) {
          this.selected_country = this.countries.find(c => number.startsWith(c.dialCode))
        }

        const dialCode = this.selected_country.dialCode;
        if (number.startsWith(dialCode)) {
          this.phone_number = number.slice(dialCode.length);
        }
      }
    },
    validatePhone(phoneNumber) {
      if (typeof phoneNumber != "string"){
        return false
      }
      /* min 6 length and numeric */
      if(!isNaN(phoneNumber) && phoneNumber.length >= 6){
        return true
      }
      return false
    },
    onFieldFocused() {
      this.focused = true;
    },
    onFieldUnfocused() {
      this.focused = false;
      this.show_error = !this.validationResult && this.phone_number;
    },
  },
  watch: {
    countries: {
      handler(val) {
        if (val.length > 0) {
          // console.log("countries populate");
          this.populateField();
        }
      },
    },
    value: {
      handler(val, oldval) {
        /* Handle pre-selecting the country through v-model */
        // console.log("value populate");
        if (!oldval) {
          this.populateField();
        }
      },
    },
    phone_number: {
      handler(num) {
        this.$emit("validated", this.validationResult);
        if (this.validationResult) {
          this.show_error = false;
        }
        let emit = "";
        if (this.phone_number) {
          emit = this.wholeNumber;
        } else {
          this.show_error = false;
        }
        this.$emit("input", emit);
      },
    },
    selected_country: {
      deep: true,
      handler(val, oldval) {
        this.$emit("validated", this.validationResult);
        // this.show_error = !this.validationResult;
        if (oldval && this.phone_number) {
          this.show_error = !this.validationResult;
        }
        if (this.phone_number) {
          this.$emit("input", this.wholeNumber);
        }

        if (val) {
          this.$emit(
            "countryUpdate",
            this.countries.find((el) => el.isoCode === val.isoCode)
          );
        }
      },
    },
  },
  computed: {
    storedCountry() {
      return this.$store.state.location.serviceCountry;
    },
    storedPhoneCountry() {
      return this.$store.state.country;
    },
    wholeNumber() {
      return `${this.selected_country.dialCode}${this.phone_number}`;
    },
    validationResult() {
      return this.validatePhone(this.phone_number);
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-field-container {
}
.phone-field-input-container {
  /* Styling */
  height: 60px;
  background: #ffffff;
  border: 1px solid #dce1e5;
  box-sizing: border-box;
  border-radius: 5px;

  /* Positioning */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;

  &.active {
    border: 1px solid #0890F1;
  }
  &.error {
    border: 1px solid #ED738C;
  }
}
.country-picker-container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: 50px;
  // width: 50px;
  .select-container {
    position: absolute;
    top: 0;
    left: 0;
    select {
      opacity: 0;
      width: 50px;
      min-height: 50px;
      outline: none;
      border: 0;
    }
  }
  .country-picker-flag-container {
    .country-picker-flag {
      height: 16px;
    }
  }
  .country-picker-arrow-container {
    /* height: 7px; */
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .country-picker-arrow {
      height: 7px;
    }
  }
  .country-picker-separator {
    // width: 1px;
    height: 25px;
    border-right: 1px solid #dce1e5;
    margin: 0 10px;
    box-sizing: border-box;
  }
}
.phone-field-notes-container {
  span {
    
    font-style: italic;
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
    color: #a6a6a6;
  }
  &.error {
    span {
      color: #ed738c;
    }
  }
}
.phone-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .phone-input-label {
    
    font-style: normal;
    font-weight: normal;
    color: #585C62;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 1px;

    &.error {
      color: #ED738C;
    }
  }

  .phone-input-number-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .prefix {
      margin-right: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
    }
    input {
      outline: none;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.2px;
      padding: 0;
      width: 100%;
      caret-color: #0890F1;
    }
  }
}
</style>
