import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import config from "./config"

const httpLink = createHttpLink({
  uri: config.api_url,
  headers: {
    "x-source": "light",
    "x-platform": "wework"
  },
})

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  link: httpLink,
  cache
})

export default apolloClient