<script>
export default {
  props: {
    priceItem: { type: Object, required: true },
    currency: {
      type: String,
      required: true,
    },
  },
  filters: {
    formatPrice: function (value) {
      const price = parseFloat(value)
      return price.toFixed(2)
    },
  },
};
</script>

<template>
  <div class="price-item">
    <div class="title-section">
      <p class="title">{{ priceItem.title }}</p>
      <p class="subtitle">{{ priceItem.additionalDescription }}</p>
    </div>
    <div class="price-section">
      <inline-svg 
        v-if="priceItem.dealItem"
        :src="require('@/assets/image/services/deal.svg')"
        height="19"
        width="19"
        class="deal-icon"
      />
      <span> {{ currency }}{{ priceItem.price | formatPrice }} </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.price-item {
  padding: 12px 0px;
  border-bottom: 1px solid #D8DCDF;
  color: #1C2026;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-section {
    p {
      margin: 0;
      padding: 0;
    }

    .title {
      font-size: 16px;
      line-height: 21px;
    }
    .subtitle {
      font-size: 14px;
      line-height: 16px;
      color: #A7A8AA;
    }
  }

  .price-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    .deal-icon {
      margin-right: 6px;
    }
  }
}
</style>