<template>
  <div class="content-box">
    <span class="contact-title">Location</span>
    <div class="location-box-header">
      <span class="body-base">{{ location.name }}</span>
      <span @click="changeClicked" class="text-button">Change</span>
    </div>
    <div class="location-box-timetable">
      <div class="timetable-container">
        <div class="time-item">
          <span class="title body-small">Drop bag by</span>
          <span class="time body-base">{{
            dropBy | timeString
          }}</span>
        </div>
        <div class="separator">
          <span class="header-h5">{{ turnaroundText }}</span>
          <img src="@/assets/image/location/arrow_right.svg" alt="" />
        </div>
        <div class="time-item">
          <span class="title body-small">Collect bag after</span>
          <span class="time body-base">{{
            collectAfter | timeString
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
    },
  },
  computed: {
    turnaroundText() {
      const d1 = new Date(this.dropBy);
      const d2 = new Date(this.collectAfter);
      const diff_h = Math.abs(d2 - d1) / (1000 * 60 * 60);
      return (Math.floor(diff_h / 24) * 24).toString() + "h";
    },
    dropBy() {
      return this.$store.getters.dropBy;
    },
    collectAfter() {
      return this.$store.getters.collectAfter;
    },
  },
  methods: {
    changeClicked(){
      this.$emit("changeClicked")
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  border-bottom: 1px solid #D8DCDF;
  padding-bottom: 10px;

  .contact-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }

  .body-base {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
.location-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
}
.location-box-timetable {
  padding-top: 8px;
}
.timetable-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .time-item {
    display: flex;
    flex-direction: column;
    span {
      display: block;
    }
    .title {
      color: #64697b;
    }
  }
  .separator {
    display: flex;
    flex-direction: column;
    margin: 0px 16px;
    span {
      color: #0890F1;
    }
    img {
      display: block;
      height: 20px;
      top: 10px;
      margin-top: -10px;
    }
  }
}
</style>
