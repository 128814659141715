<template>
  <div
    class="service-list-item-container"
    @click="onServiceSelected"
  >
    <div class="service-list-item-icon-container">
      <img :src="service.lightImage" />
    </div>
    <div class="service-list-item-body-container">
      <span class="service-list-item-body-title">{{ service.lightTitle }}</span>
      <span class="service-list-item-body-subtitle">{{
        service.lightSubtitle
      }}</span>
      <div v-if="!hidePricing" class="service-list-item-price-section">
        <span class="service-list-item-body-price-info">{{
          service.lightPricingInfo || "Priced per item"
        }}</span>
        <span
          v-if="service.lightTitle != 'Wash'"
          class="service-list-item-see-prices"
          @click.stop="seePricesClicked"
        >
          <span>See prices</span>
        </span>
      </div>
      <div
        v-if="service.lightMinimumServiceHours > 24"
        class="extra-time-section"
      >
        <img
          src="@/assets/image/services/extra_time_clock.svg"
          class="extra-time-clock"
        />
        <span class="extra-time-text"
          >{{ service.lightMinimumServiceHours }} hour service</span
        >
      </div>
    </div>
    <span class="service-list-item-left-column">
      <div>
        <img
          :style="{ display: selected ? 'block' : 'none' }"
          src="@/assets/image/check_checked.svg"
          alt=""
        />
        <img
          :style="{ display: !selected ? 'block' : 'none' }"
          src="@/assets/image/check_unchecked.svg"
          alt=""
        />
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      required: true,
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    hidePricing: { type: Boolean, default: false }
  },
  methods: {
    onServiceSelected() {
      this.$emit("serviceSelected", this.service);
    },
    seePricesClicked() {
      this.$emit("seePricesClicked", this.service);
    },
  },
};
</script>

<style lang="scss" scoped>
.service-list-item-container {
  display: flex;
  flex-direction: row;

  background: #ffffff;
  border-bottom: 1px solid #dce1e5;
  box-sizing: border-box;

  padding: 16px;
  margin-bottom: 16px;
}
.service-list-item-left-column {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
  
}
.service-list-item-see-prices {
  margin-right: 30px;
  cursor: pointer;
  
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #0890F1;
  display: inline;
}
.service-list-item-body-container {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  .service-list-item-body-title {
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    padding-bottom: 2px;
  }
  .service-list-item-body-subtitle {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #64697b;
      padding-right: 6px;
  }
  .service-list-item-price-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 4px;
    .service-list-item-body-price-info {
      
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #64697b;
      padding-right: 6px;
    }
  }
}

.service-list-item-icon-container {
  img {
    height: 50px;
  }
}
.service-list-item-body-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #2e2e3a;
}
.service-list-item-body-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #64697b;
}
.extra-time-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  .extra-time-clock {
    display: block;
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
  .extra-time-text {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ED738C;
  }
}
</style>
