<template>
  <div class="payment-note-row-container">
    <div class="payment-note-row-check">
      <img src="@/assets/image/checkmark.svg" alt="" />
    </div>
    <div class="payment-note-row-text">
      <span>
        <slot>
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>
.payment-note-row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  .payment-note-row-check {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img{
          height: 20px;
      }
  }
  .payment-note-row-text {
    margin-left: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #2e2e3a;
  }
}
</style>