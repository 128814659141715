import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApollo from 'vue-apollo'
import apolloClient from './services/apolloClient'
import Notifications from 'vue-notification'
import config from "./services/config"
import * as Sentry from "@sentry/vue";
import * as FullStory from '@fullstory/browser';
import "./services/filters"
import SentryFullStory from '@sentry/fullstory';
import VueGtm from "vue-gtm";
import InlineSvg from 'vue-inline-svg';

/* Sentry and Fullstory */
FullStory.init({ orgId: 'GZMFN' });

if(process.env.VUE_APP_MODE !== 'development'){
  Sentry.init({
    Vue,
    dsn: "https://04e30a9688814d77b032e5b2fd990c74@o166990.ingest.sentry.io/5668662",
    integrations: [new SentryFullStory('laundryheap')],
    logErrors: config.mode === "development" || config.mode === "staging" ? true : false,
    release: config.release,
    tracesSampleRate: 1,
    environment: config.mode,
  });
}

/* Google tag manager */
Vue.use(VueGtm, {
  id: "GTM-NW2SVDC",
  defer: false,
  enabled: true,
  debug: config.mode === "development" ? true : false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

/* Components */
import BottomModal from './components/lh-ui/BottomModal.vue'
Vue.component("bottom-modal", BottomModal)
Vue.use(Notifications)

/* Inline svg */
Vue.component('inline-svg', InlineSvg);

/* Global style */
import './assets/style/main.scss'

/* GraphQL */
Vue.use(VueApollo)
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

/* Intercom */
window.intercomSettings = {
  app_id: config.intercom_id
};

Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
