<template>
  <router-view></router-view>
</template>

<script>
export default {
  async mounted() {
    /* Hide intercom launcher button */
    Intercom("update", {
      hide_default_launcher: true,
    });
  },
};
</script>

<style lang="scss">
</style>
