<template>
  <div class="search-field-box">
    <img src="@/assets/image/location/search-regular.svg" alt="" />
    <input
      @focus="handleFocused(true)"
      @blur="handleFocused(false)"
      ref="searchField"
      v-model="text"
      :placeholder="placeholder"
      type="text"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      text: null,
    };
  },
  watch: {
    text: {
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleFocused(focused) {
      this.$emit("focusChanged", focused);
    },
  },
  mounted() {
    this.$refs.searchField.focus();
  },
};
</script>

<style lang="scss" scoped>
.search-field-box {
  height: 60px;
  width: 100%;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #dce1e5;
  border-radius: 5px;
  /* Positioning */
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    display: block;
    height: 24px;
    width: 24px;
  }
  input {
    margin-left: 12px;
    border: none;
    outline: none;
    width: 100%;
    /* Text */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
  }
}
</style>