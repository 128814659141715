<template>
  <div class="bottom-button-container">
    <div
      @click="mainButtonClicked"
      v-if="text"
      class="bottom-button"
      :class="{ disabled: disabled }"
    >
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    mainButtonClicked() {
      if (!this.disabled) {
        this.$emit("clicked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px;
  .bottom-button {
    background: #0890f1;
    border-radius: 4px;
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 200ms ease-in-out;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #ffffff;
    }
    &.disabled {
      background: #a6a6a6;
    }
  }
}
</style>