<template>
  <div v-if="priceService" class="wash-estimate-container">
    <span class="wash-pricing-title">The estimate for this bag is:</span>
    <div class="wash-price-box">
      <span>{{ washPrice }}</span>
    </div>
    <span class="wash-pricing-description">
      This is our standard price for Wash per bag. Your card will be charged with the final amount only after we’ve cleaned your items.
    </span>
  </div>
</template>

<script>
export default {
  props: {
    priceService: {
      type: Object,
    },
  },
  computed: {
    washPrice() {
      let price = parseInt(this.$store.state.service.lightWashPriceCents)
      price = price/100

      return `${this.currencySymbol}${price}`
    },
    currencySymbol(){
      return this.$store.state.location.serviceCountry.currency
    }
  },
};
</script>

<style lang="scss" scoped>
.wash-estimate-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  span {
    display: block;
  }
  .wash-pricing-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #2e2e3a;
  }
  .wash-price-box {
    box-sizing: border-box;
    border-radius: 2px;
    margin: 20px 0;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      color: #0890F1;
      line-height: 26px;
    }
  }
  .wash-pricing-description {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #2e2e3a;
  }
}
</style>