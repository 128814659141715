<template>
  <div class="content-box">
    <div>
      <span class="contact-title">Payment</span>
      <div v-if="native" class="saved-payment-card">
        <span class="capitalize">{{ nativeType }} pay</span>
      </div>
      <div v-else class="saved-payment-card">
        <img :src="getCardIconUrl(paymentMethod.card.brand)" alt="" srcset="" />
        {{ paymentMethod.card.last4 }}
      </div>
    </div>
    <div @click="onChangeCardClicked">
      <span class="text-button">Change</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paymentMethod: {
      type: Object,
    },
    native: {
      type: Boolean,
      default: false,
    },
    nativeType: {
      type: String
    },
  },
  methods: {
    getCardIconUrl(brand) {
      try {
        brand = brand.replace(/\s/g, "");
        brand = brand.toLowerCase();
        const img = require("../../assets/image/credit_cards/" +
          brand +
          ".svg");
        return img;
      } catch (error) {
        Sentry.captureMessage("Default card icon was returned: " + brand);
        const img = require("../../assets/image/credit_cards/default.svg");
        return img;
      }
    },
    onChangeCardClicked() {
      this.$emit("changeClicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  border-bottom: 1px solid #D8DCDF;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .contact-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }

  .body-base {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .saved-payment-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      height: 15px;
      margin-right: 8px;
    }
  }
}
.capitalize{
  text-transform: capitalize;
}
</style>