import Vue from "vue"
import moment from "moment"

Vue.filter('timeString', function (value) {
    let time = moment.parseZone(value);
    return time.calendar({
        sameDay: "ha [Today]",
        nextDay: "ha [Tomorrow]",
        nextWeek: "ha on D MMM",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: "DD/MM/YYYY",
    });
})