<template>
  <div class="roomNumber-field">
    <div
      class="roomNumber-field-container"
      :class="{
        'roomNumber-field-container-focused': focused,
        'roomNumber-field-container-error': has_error,
      }"
    >
      <span
        class="roomNumber-placeholder"
        :class="{ 'placeholder-visible': roomNumber || focused }"
        >Room number</span
      >
      <input
        @focus="onFieldFocus"
        @blur="onFieldUnfocus"
        :placeholder="focused && 'e.g. 427' || 'Room number'"
        class="roomNumber-field-input"
        v-model="roomNumber"
        type="roomNumber"
      />
    </div>
    <div class="field-notes" :class="{ 'field-notes-error': has_error }">
      <span>{{ error_message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      roomNumber: null,
      focused: false,
      has_error: false,
      error_message: null,
    };
  },
  watch: {
    roomNumber: {
      handler(val) {
        this.$emit("validated", this.validate());
        this.$emit("input", val);
      },
    },
    has_error: {
      handler(val) {},
    },
    /* Enable 2-way value binding by setting internal roomNumber value when bound value changes */
    value: {
      handler(val) {
        this.roomNumber = val;
      },
    },
  },
  methods: {
    onFieldFocus() {
      this.focused = true;
    },
    validate() {      
      if(this.roomNumber.length === 0) {
        this.error_message = "Room number is required";
        this.has_error = true;
        return false;
      } else {
        this.error_message = null;
        this.has_error = false;
        return true;
      }
    },
    onFieldUnfocus() {
      this.validate();
      this.focused = false;
    },
  },
  mounted() {
    /* If there's value, set the internal roomNumber field to it */
    this.roomNumber = this.value;
  },
};
</script>

<style lang="scss" scoped>
.roomNumber-field-container {
  background: #ffffff;
  border: 1px solid #dce1e5;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 0 16px;

  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    caret-color: #0890F1;
  }
}
.roomNumber-field-container-focused {
  border: 1px solid #0890F1;
}
.roomNumber-field-container-error {
  border: 1px solid #ED738C;
  .roomNumber-placeholder {
    color: #ED738C;
  }
}
.roomNumber-field-input {
  box-sizing: border-box;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.2px;
}
.roomNumber-placeholder {
  opacity: 0;
  display: none;
  transition: opacity 500ms ease-in-out;

  font-style: normal;
  font-weight: normal;
  color: #585C62;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1px;
}
.placeholder-visible {
  opacity: 1;
  display: inline-block;
}
.field-notes {
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  font-weight: normal;
  color: #a6a6a6;
}
.field-notes-error {
  color: #ED738C;
}
</style>
