<template>
  <div class="lh-textarea-container">
    <textarea
      v-model="text"
      :placeholder="placeholder"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      text: null,
    };
  },
  watch: {
    value: {
      handler(val){
        this.text = val
      }
    },
    text: {
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.text = this.value;
  },
};
</script>

<style lang="scss" scoped>
.lh-textarea-container {
  background: #ffffff;
  border: 1px solid #dce1e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 18px 16px;
  textarea {
    border: 0;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    letter-spacing: -0.2px;
    outline: none;
    resize: none;
  }
}
</style>