import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import apolloClient from "../services/apolloClient"
import gql from "graphql-tag";
import { endsWith, isEmpty } from "lodash";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tag_code: null,
    location: null,
    service: null,
    special_instructions: null,
    paymentmethod_id: null,
    email: null,
    telephone: null,
    roomNumber: null,
    country: null,
    created_order: null,
    saved_payment_method: null,
    saved_native_payment_method: null,
    return_flow: false,
    loading: false,
    timeslot_missed: false,
    timeslots: null,
  },
  getters: {
    invoiceManually: state => {
      return Boolean(state?.location?.invoiceManually);
    },
    emailDomain: state => {
      return state?.location?.emailDomain;
    },
    lightOrder: state => {
      return {
        qrCode: state.tag_code,
        pmToken: state.paymentmethod_id,
        serviceId: parseInt(state.service.id),
        lightLocationId: parseInt(state.location.id),
        email: state.email,
        phone: state.telephone,
        roomNumber: state.roomNumber,
        specialInstructions: state.special_instructions || "",
      }
    },
    dropBy: state => (state.timeslots ? state.timeslots.pickupAfter : state.location?.pickupAfter),
    collectAfter: state => (state.timeslots ? state.timeslots.dropoffBefore : state.location?.dropoffBefore),
    isSavedPaymentNative: state => {
      if (state.saved_native_payment_method) {
        return true
      } else {
        return false
      }
    },
    savedNativePaymentMethod: state => {
      return state.saved_native_payment_method
    },
    savedPaymentMethod: state => {
      return state.saved_payment_method || state.saved_native_payment_method
    },
    savedNativePaymentMethodType: state => {
      if (!state.saved_native_payment_method) {
        return null
      } else {
        if (state.saved_native_payment_method.card.wallet.type == "apple_pay") {
          return "apple"
        }
        if (state.saved_native_payment_method.card.wallet.type == "google_pay") {
          return "google"
        }
        return "other"
      }
    },
    hasSavedPaymentMethod: state => {
      if (state.saved_payment_method || state.saved_native_payment_method) {
        return true
      }
      return false
    },
    /* Use everywhere you want to check if return flow is possible */
    canMakeReturnFlow: state => {
      /* Manual invoice has special rules */
      if(state.location?.invoiceManually){
        /* Check if enough data */
        if(!state.location || !state.email || !state.telephone || !state.tag_code) {
          return false;
        }

        /* Check if email domain and email domain valid */
        if(state.location?.emailDomain && !endsWith(state.email, state.location?.emailDomain)){
          return false;
        }

        /* Check if room number is required and room number is available */
        if(state.location?.roomNumberRequired && !state.roomNumber){
          return false;
        }

        return state.return_flow;
      }

      /* Normal invoicing */
      if ((state.saved_payment_method || state.saved_native_payment_method) && state.location && state.email && state.telephone && state.tag_code) {
        /* Data is ok, just check if we actually want return flow */
        return state.return_flow;
      }

      return false;
    },
    selectedCountry: state => {
      return state.location?.serviceCountry
    },
  },
  mutations: {
    setTagCode(state, code) {
      state.tag_code = code
    },
    setLocation(state, location) {
      state.location = location
    },
    setService(state, service) {
      state.service = service
    },
    setSpecialInstructions(state, text) {
      state.special_instructions = text
    },
    setPaymentMethod(state, pm_id) {
      state.paymentmethod_id = pm_id
    },
    setSavedPaymentMethod(state, pm) {
      /* Check if it's a part of the wallet, then ignore it */
      if (!pm) {
        state.saved_payment_method = null
        state.paymentmethod_id = null
        return
      }
      if (pm.card.wallet) {
        return
      }
      state.saved_payment_method = pm
    },
    setEmail(state, email) {
      state.email = email
    },
    setTelephone(state, telephone) {
      state.telephone = telephone
    },
    setRoomNumber(state, roomNumber) {
      state.roomNumber = roomNumber
    },
    setCountry(state, country) {
      state.country = country
    },
    setCreatedOrder(state, order) {
      state.created_order = order
    },
    setCreatedOrderFinal(state, order) {
      state.created_order = order
    },
    setUserFlow(state, is_return_flow) {
      state.return_flow = is_return_flow
    },
    setSavedNativePaymentMethod(state, pm) {
      if (pm && pm.id) {
        state.saved_native_payment_method = pm
      } else {
        state.saved_native_payment_method = null
        state.paymentmethod_id = null
      }

    },
    setLoadingState(state, loading) {
      state.loading = loading
    },
    setMissedTimeslot(state, missed) {
      state.timeslot_missed = missed
    },
    setTimeslots(state, timeslots){
      if(!timeslots){
        state.timeslots = null
        return
      }
      if(!timeslots.pickupAfter || !timeslots.dropoffBefore){
        throw "Timeslots badly formatted"
      }
      state.timeslots = timeslots

    }
  },
  actions: {
    saveTagCode({ commit }, code) {
      commit("setTagCode", code)
    },
    saveLocation({ commit }, location) {
      commit("setLocation", location)
    },
    saveService({ commit }, service) {
      commit("setService", service)
    },
    saveSpecialInstructions({ commit }, text) {
      commit("setSpecialInstructions", text)
    },
    savePaymentMethod({ commit }, payment_method) {
      if (!payment_method) {
        commit("setPaymentMethod", null)
        commit("setSavedPaymentMethod", null)
        return
      }
      /* Delete any old saved native payment methods */
      commit("setSavedNativePaymentMethod", null)

      commit("setPaymentMethod", payment_method.id)
      commit("setSavedPaymentMethod", payment_method)
    },
    saveEmail({ commit }, email) {
      commit("setEmail", email)
    },
    saveTelephone({ commit }, telephone) {
      commit("setTelephone", telephone)
    },
    saveRoomNumber({ commit }, roomNumber) {
      commit("setRoomNumber", roomNumber)
    },
    saveCountry({ commit }, country) {
      commit("setCountry", country)
    },
    saveCreatedOrder({ commit }, order) {
      commit("setCreatedOrderFinal", order)
      /* Clear some data, because order is complete */
      commit("setTagCode", null)
      // commit("setLocation", null)
      commit("setService", null)
      commit("setSpecialInstructions", null)
      // commit("setPaymentMethod", null)
    },
    clearOrder({ commit }) {
      /* Clear everything except email, phone, location and saved payment */
      commit("setTagCode", null)
      // commit("setLocation", null)
      commit("setService", null)
      commit("setSpecialInstructions", null)
      // commit("setPaymentMethod", null)
      commit("setCreatedOrder", null)
      commit("setMissedTimeslot", false)
    },
    setReturnFlow({ commit }, is_return_flow) {
      commit("setUserFlow", is_return_flow)
    },
    openSupport({ state }){
      if(!isEmpty(state?.location)) {
        window.Intercom('showNewMessage', `I need help with my Laundryheap order at ${state?.location?.name}. `);
        return;
      }
      window.Intercom('show');
    },
    saveNativePaymentMethod({ commit }, payment_method) {
      if (payment_method.id) {
        /* Delete any old normal payment methods */
        commit("setSavedPaymentMethod", null)

        /* Set a native payment method */
        commit("setSavedNativePaymentMethod", payment_method)
        commit("setPaymentMethod", payment_method.id)
      }
    },
    loading({ commit }) {
      commit("setLoadingState", true)
    },
    loaded({ commit }) {
      commit("setLoadingState", false)
    },
    async refreshLocation({ commit, state, dispatch }, skip_checking_timeslot = false) {
      if (!state.location?.id) {
        return
      }

      const oldLocation = state.location

      const newLocation = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
        query lightLocation($id: ID!){
          lightLocation(id: $id) {
            id
            name
            url
            lat
            lng
            address
            promo
            logo
            emailDomain
            invoiceManually
            laundryheapPoint
            roomNumberRequired
            firstImage
            secondImage
            dropoffBefore
            pickupAfter
            serviceCountry {
              id
              code
              currency
              currencyIsoCode
              timezone
              url
            }
          }
        }
      `,
        variables: {
          id: state.location?.id
        },
      }).then(res => res.data.lightLocation);

      if (!skip_checking_timeslot && (oldLocation.pickupAfter !== newLocation.pickupAfter || oldLocation.dropoffBefore !== newLocation.dropoffBefore)) {
        const service = await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: gql`
            query lightServices($locationId: ID!) {
              lightServices(locationId: $locationId) {
                id
                lightTitle
                lightSubtitle
                lightPricingInfo
                lightImage
                lightWashPriceCents
                shortCode
                lightMinimumServiceHours
                lightPickupAfter
                lightPickupBefore
                lightDropoffCompleteAfter
                lightDropoffCompleteBefore
              }
            }
          `,
          variables: {
            locationId: state.location.id,
          },
        }).then(res => res.data.lightServices).then(services => {
          return services.find(s => s.id === state.service.id)
        })

        dispatch("saveNewTimeslots",{
          pickupAfter: service.lightPickupAfter,
          dropoffBefore: service.lightDropoffCompleteBefore,
        });
        commit("setMissedTimeslot", true);
      } else {
        commit("setMissedTimeslot", false);
      }

      commit("setLocation", newLocation)
    },
    saveNewTimeslots({ commit },timeslots){
      commit("setTimeslots",timeslots)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      rehydrated: store => {
        /* Disable loading screen if something weird happened */
        store.dispatch("loaded")
      }
    })
  ]
})
