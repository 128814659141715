<script>
import { defineComponent } from 'vue';
import BottomDoubleButton from '@/components/lh-ui/BottomDoubleButton.vue';

export default defineComponent({
  components: { BottomDoubleButton },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  methods: {
    async onContactSupportClicked() {
      await this.$store.dispatch('openSupport');
      this.$emit('ok');
    },
    onCloseClicked() {
      this.$emit('ok');
    },
  },
});
</script>

<template>
  <div class="phone-taken-prompt">
    <div v-if="data" class="phone-taken-content">
      <span class="header-h1 title">Do you already have an account?</span>
      <p class="phone-taken-paragraph">
        This phone number is already used with the following email:
        <strong>{{ data.email }}</strong>
      </p>
      <p class="phone-taken-paragraph">
        Use the above email or contact our customer support if you don't have access.
      </p>
    </div>
    <bottom-double-button
      main-text="Update contact info"
      alt-text="Contact support"
      @mainClicked="onCloseClicked"
      @altClicked="onContactSupportClicked"
    ></bottom-double-button>
  </div>
</template>

<style lang="scss" scoped>
.phone-taken-prompt {
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  .phone-taken-content {
    padding: 14px 16px 8px 16px;
    display: flex;
    flex-direction: column;

    .title {
      padding-right: 32px;
      margin-bottom: 12px;
    }

    .phone-taken-paragraph {
      margin: 8px 0;
    }
  }
}
</style>
