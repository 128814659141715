<template>
  <div class="help-screen-container safearea-bottom">
    <template v-if="page == 'services'">
      <div class="help-section">
        <span class="heading">How to pack?</span>
        <span class="line"
          >Pack all items that require the same type of cleaning in one bag.
        </span>
      </div>
      <div class="help-section">
        <span class="heading">Can’t fit everything in one bag?</span>
        <span class="line"
          >Please split into two bags and book two separate orders.
        </span>
      </div>
      <div class="help-section">
        <span class="heading">Do you need two different services?</span>
        <span class="line"
          >Please pack them in separate bags and book two orders, one for each
          service.</span
        >
      </div>
    </template>
    <!-- v-if="page == 'location'" -->
    <template v-else>
      <div class="help-section">
        <span class="heading">How it works</span>
        <span class="line">1. Scan a new, unused QR tag</span>
        <span class="line"
          >2. Select cleaning type and add payment details</span
        >
        <span class="line">3. Attach the tag to the bag</span>
        <span class="line">4. Drop the bag at the collection point</span>
      </div>
      <div v-if="!invoiceManually" class="help-section">
        <span class="heading">Prices</span>
        <span class="line"
          >You can see all prices when selecting the type of cleaning.</span
        >
        <span class="line"
          >No minimum order. No prepayment. Free collection and free
          delivery.</span
        >
      </div>
    </template>

    <div class="small-device-spacer"></div>
    <contact-us-box title="Any questions?" :small="helpBoxSmall"></contact-us-box>
  </div>
</template>

<script>
import ContactUsBox from "./ContactUsBox.vue";
export default {
  components: { ContactUsBox },
  props: {
    page: {
      type: String,
    },
    invoiceManually: {type: Boolean, default: false},
  },
  computed: {
    helpBoxSmall() {
      return this.page == "location" || this.page == "services";
    },
  },
};
</script>

<style lang="scss" scoped>
.help-screen-container {
  background: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  padding: 24px 16px 16px 16px;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  .small-device-spacer {
    flex: 1;
  }
}

.help-section {
  margin-bottom: 24px;
  span {
    display: block;
  }
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: #2e2e3a;
    margin-bottom: 4px;
  }
  .line {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: #2e2e3a;
  }
  .line:not(:last-child) {
    margin-bottom: 3px;
  }
}
</style>
