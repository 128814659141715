<template>
  <div v-if="location" class="location-details-container safearea-bottom">
    <div
      class="location-details-image-container hide-small-height"
      :style="{ backgroundImage: 'url(' + location.firstImage + ')' }"
    ></div>
    <div v-if="location.promo" class="promo-message-container">
      <span class="header-h5">
        {{ location.promo }}
      </span>
    </div>
    <div class="location-details-header-container">
      <img src="@/assets/image/wework_logo_small.svg" height="50" width="50" />
      <div>
        <span class="details-location-name body-base">
          {{ location.name }}
        </span>
        <span class="details-location-address body-small">
          {{ location.address }}
        </span>
        <span class="details-location-lhpoint body-small">
          {{ location.laundryheapPoint }}
        </span>
      </div>
    </div>
    <div class="location-details-timetable">
      <span class="timetable-title header-h5">Timetable</span>
      <div class="timetable-container">
        <div class="time-item">
          <span class="title body-small">Drop bag by</span>
          <span class="time body-base">{{
            location.pickupAfter | timeString
          }}</span>
        </div>
        <div class="separator">
          <span class="header-h5">{{ turnaroundText }}</span>
          <img src="@/assets/image/location/arrow_right.svg" alt="" />
        </div>
        <div class="time-item">
          <span class="title body-small">Collect bag after</span>
          <span class="time body-base">{{
            location.dropoffBefore | timeString
          }}</span>
        </div>
      </div>
    </div>
    <div class="small-screen-spacer"></div>
    <div @click="locationConfirmed" class="location-select-button">
      <span class="button-text">Select this location</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: false,
    },
  },
  methods: {
    locationConfirmed() {
      this.$emit("locationConfirmed", this.location);
    },
  },
  computed: {
    turnaroundText() {
      const d1 = new Date(this.location.pickupAfter);
      const d2 = new Date(this.location.dropoffBefore);
      const diff_h = Math.abs(d2 - d1) / (1000 * 60 * 60);
      return (Math.floor(diff_h / 24) * 24).toString() + "h";
    },
  },
};
</script>

<style lang="scss" scoped>
.location-details-container {
  background-color: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media only screen and (max-width: 350px) {
  .small-screen-spacer {
    flex: 1;
  }
}

.location-details-image-container {
  height: 211px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 350px) {
  .location-details-image-container {
    height: 165px;
  }
}

@media only screen and (max-height: 600px) {
  .hide-small-height {
    display: none;
  }
}

.location-details-image {
  width: 100%;
  overflow: hidden;
}
.promo-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #f3fbff;
  border: 1px solid #d4effc;
  box-sizing: border-box;
  span {
    color: #0890F1;
  }
}
.location-details-header-container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  img {
    margin-right: 12px;
    border: 2px solid #D8DCDF;
    border-radius: 50%;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .details-location-name {
      margin-bottom: 4px;
    }
    .details-location-address {
      color: #64697b;
    }
    .details-location-lhpoint {
      color: #64697b;
    }
  }
}

.location-details-timetable {
  padding: 0px 16px 16px 16px;
  .timetable-container {
    margin-top: 2px;
    min-height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .time-item {
      border: 2px solid #dce1e5;
      box-sizing: border-box;
      border-radius: 5px;
      flex: 1;
      min-height: 64px;
      padding: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        display: block;
        text-align: center;
      }
      .title {
        color: #64697b;
      }
    }
    .separator {
      display: flex;
      flex-direction: column;
      margin: 0px 16px;
      span {
        color: #0890F1;
      }
      img {
        display: block;
        height: 20px;
        top: 10px;
        margin-top: -10px;
      }
    }
  }
}

.location-select-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px;
  height: 54px;
  margin: 8px;
  border-radius: 4px;

  cursor: pointer;
  background: #0890F1;

  span {
    color: #ffffff;
  }
}
</style>
