<template>
  <page-container page="confirmation">
    <div class="order-confirmation-header">
      <img src="@/assets/image/confirmation/checkmark-heart.svg" alt="" srcset="" />
      <span class="order-confirmation-header-title"
        >Order confirmed</span
      >
      <span class="order-confirmation-header-notice body-big"
        >A copy of your order confirmation was sent to {{ email }}</span
      >
    </div>
    <div class="order-confirmation-instructions-list">
      <span class="instruction-list-title header-h3">Next steps</span>
      <div class="instructions-list-row">
        <img
          class="instructions-list-image"
          src="@/assets/image/1_big.svg"
          alt=""
        />
        <div class="instructions-list-content">
          <div class="instruction-title header-h4">Attach the QR tag</div>
          <span class="instruction-text"
            >
            Please make sure the tag <b>{{ createdOrder.number }}</b> is securely attached to your bag.</span
          >
        </div>
      </div>
      <div class="instructions-list-row">
        <img
          class="instructions-list-image"
          src="@/assets/image/2_big.svg"
          alt=""
        />
        <!-- TODO: dynamic dropoff times -->
        <div class="instructions-list-content">
          <div class="instruction-title header-h4">Drop the bag</div>
          <span v-if="selectedLocation.roomNumberRequired" class="instruction-text">
            Drop your bag <strong class="underline">with the laundryheap driver</strong> by {{ dropByForRoomNumber | timeString }}.
          </span>
          <span v-else class="instruction-text">
            Drop your bag in the assigned area by {{ dropBy | timeString }}.
          </span>
        </div>
      </div>
      <div class="instructions-list-row">
        <img
          class="instructions-list-image"
          src="@/assets/image/3_big.svg"
          alt=""
        />
        <div class="instructions-list-content">
          <div class="instruction-title header-h4">Collect your clean clothes</div>
          <span v-if="selectedLocation.roomNumberRequired" class="instruction-text">
            Your clean clothes will be returned after {{ collectAfter | timeString }}. We will send you a text to remind you.
          </span>
          <span v-else class="instruction-text">
            Your clean clothes will be ready for collection after {{ collectAfter | timeString }}. We will send you a text to remind you.
          </span>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import PageContainer from "../components/PageContainer.vue";
import moment from 'moment';

export default {
  components: {
    PageContainer,
  },
  computed: {
    createdOrder() {
      return this.$store.state.created_order;
    },
    email() {
      return this.$store.state.email;
    },
    dropBy() {
      return this.$store.getters.dropBy;
    },
    dropByForRoomNumber() {
      var date = new moment.parseZone(this.$store.getters.dropBy);
      var newDate = date.add(moment.duration(2, 'hours'));
      return newDate;
    },
    collectAfter() {
      return this.$store.getters.collectAfter;
    },
    selectedLocation() {
      return this.$store.state.location;
    },
  },
  beforeRouteLeave: (to, from, next) => {
    next(false);
  },
};
</script>

<style lang="scss" scoped>
.order-confirmation-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #ffffff;

  padding: 8px;
  margin-bottom: 32px;
  margin-top: 16px;
  img {
    display: block;
    width: 70px;
    height: 70px;
  }
  .order-confirmation-header-title {
    margin: 4px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
  }
  .order-confirmation-header-notice {
  }
}
.order-confirmation-instructions-list {
  display: flex;
  flex-direction: column;
  .instruction-list-title{
    margin-bottom: 16px;
  }
  .instructions-list-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
    .instructions-list-image {
      margin-right: 8px;
      display: block;
      height: 50px;
    }
    .instructions-list-content {
      .instruction-title {
        }
      .instruction-text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-top: 2px;
      }
      .instruction-notes {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #a6a6a6;
        margin-top: 4px;
      }
    }
  }

  .underline {
    text-decoration: underline;
  }
}
</style>
