import Vue from 'vue'
import VueRouter from 'vue-router'
import Location from '../views/Location'
import Services from '../views/Services'
import Payment from '../views/Payment'
import Comfirmation from "../views/Confirmation.vue"
import InvalidCode from "../views/Error/InvalidCode.vue"
import NoCode from "../views/Error/NoCode.vue"

import store from '../store'

Vue.use(VueRouter)

function qrCodeValid(code) {
  return code.length == 7
}

const routes = [
  {
    path: '/location',
    name: 'Location',
    component: Location,
    beforeEnter: (to, from, next) => {
      /* If there's no qr code, you shouldn't be here */
      if (!store.state.tag_code) {
        next({ name: "Nocode" })
      } else {
        next()
      }
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    beforeEnter: (to, from, next) => {
      /* If there's no qr code scanned, show no code error */
      if (!store.state.tag_code) {
        next({ name: "Nocode" })
      } else if (!store?.state?.location?.id) {
        /* If there's no location saved, go back to location screen */
        next({ name: "Location" })
      } else {
        /* If everything checks out, show services screen */
        next()
      }
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    beforeEnter: (to, from, next) => {
      /* If there's no qr code or no selected location or service, you shouldn't be here */
      if (!store.state.tag_code) {
        next({ name: "Nocode" })
      } else if (!store?.state?.location?.id) {
        next({ name: "Location" })
      } else if (!store.state.service) {
        next({ name: "Services" })
      }
      else {
        next()
      }
    }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Comfirmation,
    beforeEnter: (to, from, next) => {
      /* If there's no created_order data in store, you shouldn't be here */
      if (!store.state.created_order) {
        next({ name: "Error" })
      } else {
        next()
      }
    },
  },
  {
    path: '/error',
    name: "Error",
    component: InvalidCode
  },
  {
    path: '/',
    name: 'Nocode',
    component: NoCode
  },
  {
    path: '/:qrcode',
    name: 'Qrcode',
    beforeEnter: (to, from, next) => {
      if (to.params.qrcode && qrCodeValid(to.params.qrcode)) {
        store.dispatch('clearOrder')
        const code = to.params.qrcode
        store.dispatch('saveTagCode', code.toUpperCase())
        next({ name: "Location" })
        return

        // Deleted the automatic redirect to services
        /* if(store.getters.canMakeReturnFlow){
          next({ name: "Services" })
        }else{
          next({ name: "Location" })
        } */
      } else {
        next({ name: "Error" })
        return
      }

    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
