<template>
  <div class="modal-container" :style="{ display: display, opacity: opacity }">
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      display: "none",
      opacity: 0,
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          document.documentElement.style.overflow = "hidden";
          document.body.style.overflow = "hidden";

          /* If modal has to be shown, we first need to unset display:none, and then add the active class, so animation works */

          this.display = "flex";
          setTimeout(() => {
            this.opacity = 1;
          }, 1);
        } else {
          document.documentElement.style.overflow = "auto";
          document.body.style.overflow = "auto";
          this.opacity = 0;
          setTimeout(() => {
            this.display = "none";
          }, 350);
        }
      },
    },
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "auto";
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  opacity: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transition: all 300ms ease-in-out;

  /* z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #2e2e3a;
  overflow: auto;
  width: 100vw;

  visibility: visible;
  transition: all 300ms ease-in-out; */
  p,
  h1 {
    margin: 0;
  }
}
.modal-content {
  // display: flex;
  // flex-direction: column;
  flex: 1;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* scroll-behavior: smooth; */
}
</style>