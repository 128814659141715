<template>
  <div class="location-card" @click="locationItemClicked">
    <div
      class="location-image-container"
      v-show="expanded"
      :style="{ backgroundImage: 'url(' + location.firstImage + ')' }"
    ></div>
    <div class="location-card-details">
      <div class="details-title">
        <span class="header-h4">{{ location.name }}</span>
        <br />
        <span class="location-address body-small">{{ location.address }}</span>
      </div>
      <div class="details-button">
        <img src="@/assets/image/arrow_new.svg" alt="" class="arrow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      windowHeight: 0,
    };
  },
  methods: {
    locationItemClicked() {
      this.$emit("locationClicked", this.location);
    },
  }
};
</script>

<style lang="scss" scoped>
.location-card {
  border: 1px solid #dce1e5;
  border-radius: 5px;
  margin-bottom: 16px;
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  min-height: 64px;
}
.location-card-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
}

.details-button {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .arrow {
    transform: rotate(180deg);
  }
}
.location-image-container {
  height: 193px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.location-image {
  width: 100%;
}

.location-address {
  color: #64697b;
}
</style>