<template>
  <div
    id="spinner-fullscreen"
    class="spinner-background"
    :class="{ hidden: hide_spinner }"
    ref="spinner"
  >
    <img src="@/assets/image/common/spinner.svg" />
  </div>
</template>

<script>
export default {
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hide_spinner: true,
    };
  },
  methods: {
    handleDisplay() {
      const val = this.shown;
      const el = document.getElementById("spinner-fullscreen");
      if (val) {
        el.style.display = "flex";
        setTimeout(() => {
          this.hide_spinner = false;
        }, 1);
      } else {
        this.hide_spinner = true;
        setTimeout(() => {
          el.style.display = "none";
        }, 300);
      }
    },
  },
  watch: {
    shown: {
      handler(val) {
        this.handleDisplay();
      },
    },
  },
  mounted() {
    this.handleDisplay();
  },
};
</script>

<style lang="scss" scoped>
.spinner-background {
  background: #2e2e3a;
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
  img {
    display: block;
    opacity: 1;
    height: 64px;
    width: 64px;
  }
  &.hidden {
    opacity: 0;
  }
}
</style>