<template>
  <page-container
    page="services"
    :headerText="
      canMakeReturnFlow
        ? 'Select type of cleaning'
        : '2 - Select type of cleaning'
    "
  >
    <div class="service-list-container">
      <service-list-item
        v-for="service in lightServices"
        :key="service.id"
        :service="service"
        @serviceSelected="onServiceSelected"
        :selected="isServiceSelected(service)"
        :hidePricing="invoiceManually"
        @seePricesClicked="onSeePricesClicked(service)"
      >
      </service-list-item>
    </div>

    <div class="services-special-instructions">
      <span>Any special instructions?</span>
      <lh-text-area
        v-model="special_instructions"
        placeholder="Please add any special cleaning instructions or requests."
      >
      </lh-text-area>
    </div>

    <full-screen-modal v-model="show_prices_modal">
      <pricelist
        :service="selected_pricelist_service"
        @close="show_prices_modal = false"
      >
      </pricelist>
    </full-screen-modal>

    <bottom-modal v-model="show_return_flow_modal">
      <return-flow v-if="canMakeReturnFlow"></return-flow>
    </bottom-modal>

    <bottom-modal v-model="show_longer_service_modal">
      <long-service-prompt
        @proceedClicked="onProceedLongServiceClicked"
        @cancelClicked="onCancelLongServiceClicked"
        :service="selected_long_service"
      ></long-service-prompt>
    </bottom-modal>

    <template v-slot:bottom-floating>
      <bottom-button
        :disabled="bottom_button_disabled"
        @clicked="goToPaymentClicked"
        :text="bottomButtonText"
      >
      </bottom-button>
    </template>
  </page-container>
</template>

<script>
import PageContainer from "../components/PageContainer.vue";
import ServiceListItem from "../components/service/ServiceListItem.vue";
import LhTextArea from "../components/lh-ui/LhTextArea.vue";
import BottomButton from "../components/lh-ui/BottomButton.vue";
import Pricelist from "../components/service/PriceList.vue";
import FullScreenModal from "../components/lh-ui/FullScreenModal.vue";
import BottomModal from "../components/lh-ui/BottomModal.vue";
import ReturnFlow from "../components/return-flow/ReturnFlow.vue";
import LongServicePrompt from "../components/service/LongServicePrompt.vue";

import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  name: "Services",
  components: {
    PageContainer,
    ServiceListItem,
    LhTextArea,
    BottomButton,
    Pricelist,
    FullScreenModal,
    BottomModal,
    ReturnFlow,
    LongServicePrompt,
  },
  data() {
    return {
      lightServices: null,
      selected_service: null,
      special_instructions: null,
      show_prices_modal: false,
      selected_pricelist_service: null,
      bottom_button_disabled: true,
      /* Return flow stuff */
      show_return_flow_modal: false,
      /* Long service stuff */
      show_longer_service_modal: false,
      selected_long_service: null,
    };
  },
  apollo: {
    lightServices: {
      query: gql`
        query lightServices($locationId: ID!) {
          lightServices(locationId: $locationId) {
            id
            lightTitle
            lightSubtitle
            lightPricingInfo
            lightImage
            lightWashPriceCents
            shortCode
            lightMinimumServiceHours
            lightPickupAfter
            lightPickupBefore
            lightDropoffCompleteAfter
            lightDropoffCompleteBefore
          }
        }
      `,
      variables() {
        return {
          locationId: this.selectedLocation.id,
        };
      },
    },
  },
  watch: {
    selected_service: {
      handler(val) {
        if (val) {
          this.$store.dispatch("saveService", val);
          if (val.lightMinimumServiceHours <= 24) {
            this.$store.dispatch("saveNewTimeslots", null);
          }
          this.bottom_button_disabled = false;
        } else {
          this.bottom_button_disabled = true;
        }
      },
    },
    lightServices: {
      deep: true,
      handler(services) {
        if(services?.length === 1) {
          this.onServiceSelected(services[0]);
        }
      }
    }
  },
  computed: {
    selectedCountry() {
      return this.$store.state.location.serviceCountry.code;
    },
    selectedLocation() {
      return this.$store.state.location;
    },
    canMakeReturnFlow() {
      return this.$store.getters.canMakeReturnFlow;
    },
    bottomButtonText(){
      if(this.canMakeReturnFlow){
        return 'Review order';
      }
      return 'Continue';
    },
    ...mapGetters([
      "invoiceManually",
    ]),
  },
  methods: {
    onServiceSelected(service) {
      if (this.selected_service && this.selected_service.id === service.id) {
        return;
      }
      if (
        service.lightMinimumServiceHours &&
        service.lightMinimumServiceHours > 24
      ) {
        this.selected_long_service = service;
        this.showLongerServiceModal();
        return;
      }
      this.selected_service = service;
    },
    onSeePricesClicked(service) {
      this.selected_pricelist_service = service;
      this.show_prices_modal = true;
    },
    goToPaymentClicked() {
      /* Save all the data immediately */
      this.$store.dispatch("saveService", this.selected_service);
      this.$store.dispatch(
        "saveSpecialInstructions",
        this.special_instructions
      );

      if (!this.bottom_button_disabled) {
        if (this.canMakeReturnFlow) {
          this.show_return_flow_modal = true;
        } else {
          this.$router.push("/payment");
        }
      }
    },
    isServiceSelected(service) {
      if (service && this.selected_service) {
        return service.id == this.selected_service.id;
      } else {
        return false;
      }
    },
    showLongerServiceModal() {
      this.show_longer_service_modal = true;
    },
    onCancelLongServiceClicked() {
      this.selected_long_service = null;
      this.show_longer_service_modal = false;
    },
    onProceedLongServiceClicked() {
      this.selected_service = this.selected_long_service;
      this.$store.dispatch("saveNewTimeslots", {
        pickupAfter: this.selected_service.lightPickupAfter,
        dropoffBefore: this.selected_service.lightDropoffCompleteBefore,
      });
      this.selected_long_service = null;
      this.show_longer_service_modal = false;
    },
  },
  mounted() {
    /* Check the store if a service is already selected */
    // this.selected_service = this.$store.state.service;
    this.special_instructions = this.$store.state.special_instructions;
  },
  beforeRouteLeave(to, from, next) {
    /* Save information before leaving route */
    if (this.show_prices_modal) {
      this.show_prices_modal = false;
      next(false);
    } else {
      this.$store.dispatch("saveService", this.selected_service);
      this.$store.dispatch(
        "saveSpecialInstructions",
        this.special_instructions
      );
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.step-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.step-header {
  margin: 20px 0px 0px 0px;
}
.service-list-container {
  // margin: 16px 0px;
}
.services-special-instructions {
  margin-top: 16px;
  margin-bottom: 40px;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 2px;
    display: block;
    margin-bottom: 10px;
  }
}
</style>
