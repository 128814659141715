<template>
  <div class="email-field">
    <div
      class="email-field-container"
      :class="{
        'email-field-container-focused': focused,
        'email-field-container-error': has_error,
      }"
    >
      <span
        class="email-placeholder"
        :class="{ 'placeholder-visible': email || focused }"
        >Email address</span
      >
      <input
        @focus="onFieldFocus"
        @blur="onFieldUnfocus"
        :placeholder="focused && 'e.g. youlooknicetoday@email.com' || 'Email address'"
        class="email-field-input"
        v-model="email"
        type="email"
      />
    </div>
    <div class="field-notes" :class="{ 'field-notes-error': has_error }">
      <span>{{ error_message || notes }}</span>
    </div>
  </div>
</template>

<script>
import { endsWith } from "lodash";

export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    notes: {
      type: String,
    },
    emailDomain: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      email: null,
      focused: false,
      has_error: false,
      error_message: null,
    };
  },
  watch: {
    email: {
      handler(val) {
        this.$emit("validated", this.validate());
        this.$emit("input", val);
      },
    },
    has_error: {
      handler(val) {},
    },
    /* Enable 2-way value binding by setting internal email value when bound value changes */
    value: {
      handler(val) {
        this.email = val;
      },
    },
  },
  methods: {
    onFieldFocus() {
      this.focused = true;
    },
    validate() {
      const email_valid = this.validateEmail(this.email);
      const domain_valid = this.validateDomain(this.email);

      if (email_valid && domain_valid) {
        this.has_error = false;
        this.error_message = null;
        return true;
      } else if(!email_valid) {
        this.error_message = "Email is in the wrong format";
        this.has_error = true;
        return false;
      } else if(!domain_valid) {
        this.error_message = `Only emails ending with ${this.emailDomain} are supported on this location`
        this.has_error = true;
        return false;
      } else {
        return true;
      }
    },
    onFieldUnfocus() {
      this.validate();
      this.focused = false;
    },
    validateDomain(email) {
      if (!email) {
        return true;
      }

      if(this.emailDomain) {
        if (!endsWith(email, this.emailDomain)) {
          return false;
        }
      }

      return true;
    },
    validateEmail(email) {
      if (!email) {
        return true;
      }

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  mounted() {
    /* If there's value, set the internal email field to it */
    this.email = this.value;
  },
};
</script>

<style lang="scss" scoped>
.email-field-container {
  background: #ffffff;
  border: 1px solid #dce1e5;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 0 16px;

  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    caret-color: #0890F1;
  }
}
.email-field-container-focused {
  border: 1px solid #0890F1;
}
.email-field-container-error {
  border: 1px solid #ED738C;
  .email-placeholder {
    color: #ED738C;
  }
}
.email-field-input {
  box-sizing: border-box;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.2px;
}
.email-placeholder {
  opacity: 0;
  display: none;
  transition: opacity 500ms ease-in-out;

  font-style: normal;
  font-weight: normal;
  color: #585C62;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1px;
}
.placeholder-visible {
  opacity: 1;
  display: inline-block;
}
.field-notes {
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  font-weight: normal;
  color: #a6a6a6;
}
.field-notes-error {
  color: #ED738C;
}
</style>
