<template>
  <div class="add-credit-card-component">
    <div class="credit-card-modal-title">
      <span class="header-h3">Add credit card</span>
    </div>
    <div class="hr-light" />
    <div class="credit-card-modal-content">
      <div class="form-card-element" ref="cardElement"></div>
    </div>
    <bottom-double-button
      mainText="Save"
      @mainClicked="onSaveCardClicked"
      :disabled="card_form_incomplete"
    ></bottom-double-button>
  </div>
</template>

<script>
import gql from "graphql-tag";
import BottomDoubleButton from "./BottomDoubleButton.vue";

export default {
  components: {
    BottomDoubleButton,
  },
  props: {
    country: String,
  },
  data() {
    return {
      stripePublishableKey: null,
      stripe: null,
      card: null,
      stripe_processing: false,
      card_form_incomplete: true,
    };
  },
  methods: {
    initStripeCardForm() {
      this.stripe = Stripe(this.stripePublishableKey);
      let elements = this.stripe.elements();
      this.card = elements.create("card", { hidePostalCode: true });
      this.card.on("change", this.cardChangeHandler);
      this.card.mount(this.$refs.cardElement);
    },
    onSaveCardClicked() {
      this.stripe_processing = true;
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.card,
        })
        .then((res) => {
          this.stripe_processing = false;
          if (res.error) {
            this.$notify({ group: "error", text: res.error.message });
            return;
          }
          const pm = res.paymentMethod;
          if (pm.id) {
            /* emit payment method */
            this.$emit("paymentmethod", pm);
          }
        })
        .catch((err) => {
          this.stripe_processing = false;
          throw err;
        });
    },
    cardChangeHandler(event) {
      if (!event.complete) {
        this.card_form_incomplete = true;
      } else {
        this.card_form_incomplete = false;
      }
    },
  },
  watch: {
    stripePublishableKey: {
      handler(val) {
        if (val) {
          this.initStripeCardForm();
        }
      },
    },
  },
  computed: {
    email() {
      return this.$store.state.email;
    },
  },
  apollo: {
    /* stripePublishableKey: {
      query: gql`
        query stripePublishableKey($serviceCountry: ServiceCountryEnum!, $mail: String! ) {
          stripePublishableKey(serviceCountry: $serviceCountry, mail: $mail)
        }
      `,
      variables() {
        return {
          serviceCountry: this.country,
          mail: this.email
        };
      },
    }, */
    stripePublishableKey: {
      query: gql`
        query stripePublishableKey($serviceCountry: ServiceCountryEnum!) {
          stripePublishableKey(serviceCountry: $serviceCountry)
        }
      `,
      variables() {
        return {
          serviceCountry: this.country,
        };
      },
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.add-credit-card-component {
  background: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 16px 16px 0px 0px;
}
.credit-card-modal-title {
  padding: 18px 16px 14px 16px;
}
.credit-card-modal-content {
  padding: 16px;
  .form-card-element {
    border: 1px solid #dce1e5;
    padding: 16px;
  }
}
</style>