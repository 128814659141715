<template>
  <div class="price-list-container">
    <div
      v-for="psec in priceService.priceSections"
      :key="psec.title"
      class="price-section-container"
    >
      <!-- {{ psec.title }} -->
      <div v-for="pgrp in psec.priceGroups" :key="pgrp.title">
        <div class="price-group-title-container">
          <span>{{ pgrp.title }}</span>
        </div>

        <div v-for="i in pgrp.priceItems" :key="i.title" class="item-row">
          <span class="item-name">{{ i.title }}</span>
          <span class="separator"></span>
          <span class="item-price"
            >{{ currency }}{{ i.price | formatPrice }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priceService: {
      type: Object,
    },
    nopopular: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
    },
  },
  filters: {
    formatPrice: function (value) {
      const price = parseFloat(value);
      return price.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-list-container {
  padding-bottom: 24px;
  .price-section-container:first-child {
    margin-top: 40px;
  }
}
.price-group-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 56px;
  margin-bottom: 16px;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #a6a6a6;
  }
}
.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .item-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
  }
  .separator {
    flex: 1;
    border-bottom: 2px dotted #2e2e3a;
    margin-top: 9px;
    margin-left: 4px;
    margin-right: 4px;
    // margin: 0 6px;
  }
  .item-price {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}
.item-row:not(:last-child) {
  margin-bottom: 16px;
}
</style>