<template>
  <div class="double-button-container" :class="{ 'shadow-light': shadow }">
    <div
      class="double-button-main"
      :class="{ disabled: disabled }"
      @click="mainClicked"
    >
      <span class="button-text">{{ mainText }}</span>
    </div>
    <div
      v-show="altText"
      class="double-button-alt"
      :class="{ disabled: disabled }"
    >
      <span @click="altClicked">{{ altText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mainText: {
      type: String,
    },
    altText: {
      type: String,
    },
    shadow: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    mainClicked() {
      if (!this.disabled) {
        this.$emit("mainClicked");
      }
    },
    altClicked() {
      if (!this.disabled) {
        this.$emit("altClicked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.double-button-container {
  padding: 8px;
  .double-button-main {
    background: #0890F1;
    border-radius: 4px;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 200ms ease-in-out;
    span {
      color: #ffffff;
    }
    &.disabled {
      background: #a6a6a6;
    }
  }
  .double-button-alt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px 8px 8px 8px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
    }
  }
}

.shadow-light {
  box-shadow: 0px 0px 8px rgba(198, 198, 198, 0.5);
}
</style>