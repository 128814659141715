<script>
export default {
  methods: {
    openIntercom() {
      Intercom('show');
    },
  },
};
</script>

<template>
  <div class="cant-find-item-container">
    <div class="body">
      <p class="title">Can't find your item?</p>
      <p class="subtitle" @click="openIntercom">Contact us</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cant-find-item-wrapper {
  margin-top: 16px;
}

.cant-find-item-container {
  background-color: #BAEBFF;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    margin: 0;
    padding: 0;
  }

  .title {
    color: #202B93;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .subtitle {
    color: #0890F1;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
}
</style>