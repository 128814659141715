<template>
  <div class="container">
    <div class="content">
      <h1 class="header-h1">
        This service takes {{ service && service.lightMinimumServiceHours }} hours to complete
      </h1>
      <span>You'll get your items back on:</span>
      <br>
      <span class="time-box">
        {{ service && service.lightDropoffCompleteBefore | timeString }}
        <!-- {{ timeslots && timeslots.dropoff | timeString }} -->
      </span>
    </div>
    <bottom-double-button mainText="Ok, proceed" altText="Go back" @mainClicked="$emit('proceedClicked')"  @altClicked="$emit('cancelClicked')">
    </bottom-double-button>
  </div>
</template>

<script>
import BottomDoubleButton from "../lh-ui/BottomDoubleButton.vue"
export default {
  props: {
    service: {
      type: Object,
    },
  },
  components: {
    BottomDoubleButton,
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  .content{
    padding: 16px;
    h1{
      margin: 16px 0;
    }
    span{
      display: inline-block;
    }
    span.time-box{
      margin-top: 12px;
      padding: 12px;
      border: 2px solid #DCE1E5;
    }
  }
}
</style>