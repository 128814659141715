<template>
  <div class="content-box">
    <div>
      <span class="contact-title">Contact</span>
      <div class="contact-data">
        <span class="body-base">{{ email }} </span>
        <span class="body-base">{{ phone }}</span>
        <span v-if="roomNumber" class="body-base">Room number: {{ roomNumber }}</span>
      </div>
    </div>
    <div class="change-button">
      <span @click="changeClicked" class="text-button">Change</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
    },
    phone: {
      type: String,
    },
    roomNumber: {
      type: String,
    },
  },
  methods: {
    changeClicked(){
      this.$emit("changeClicked")
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  border-bottom: 1px solid #D8DCDF;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .contact-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }

  .body-base {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .contact-data {
    span {
      display: block;
    }
    span:not(:last-child){
      margin-bottom: 4px;
    }
  }
}
</style>