<template>
  <div class="change-contact-form">
    <div class="change-contact-form-header">
      <span class="header-h3">Edit contact</span>
    </div>
    <div class="hr-light"></div>
    <div class="change-contact-modal-content">
      <email-field
        v-model="email_new"
        :emailDomain="emailDomain"
        notes="We will use it to send order confirmation and invoice."
        @validated="onEmailValidation"
      ></email-field>
      <div class="spacer"></div>
      <phone-input-new
        notes="We will use it only to send your order updates."
        v-model="phone_new"
        @validated="onPhoneValidation"
        @countryUpdate="onCountryUpdate"
      >
      </phone-input-new>
      <template v-if="selectedLocation.roomNumberRequired">
        <div class="spacer"></div>
        <room-number-field
          v-model="roomNumber_new"
          @validated="onRoomNumberValidation"
        />
      </template>
    </div>
    <bottom-double-button
      :disabled="!dataValid"
      mainText="Save"
      @mainClicked="onSaveClicked"
    ></bottom-double-button>
  </div>
</template>

<script>
import EmailField from "../lh-ui/EmailField.vue";
import RoomNumberField from "../lh-ui/RoomNumberField.vue";
import PhoneInputNew from "../lh-ui/PhoneInputNew.vue";
import BottomDoubleButton from "../lh-ui/BottomDoubleButton.vue";
import {mapGetters} from "vuex";
export default {
  components: {
    EmailField,
    RoomNumberField,
    BottomDoubleButton,
    PhoneInputNew,
  },
  props: {
    email: String,
    country: Object,
    phone: String,
    roomNumber: String,
    selectedLocation: Object,
  },
  data() {
    return {
      phone_new: null,
      country_new: null,
      roomNumber_new: null,
      email_new: null,
      phone_valid: false,
      roomNumber_valid: false,
      email_valid: false,
    };
  },
  methods: {
    onSaveClicked() {
      this.$emit("saveClicked", {
        email: this.email_new,
        country: this.country_new,
        phone: this.phone_new,
        roomNumber: this.roomNumber_new,
      });
    },
    onPhoneValidation(valid) {
      this.phone_valid = valid;
    },
    onRoomNumberValidation(valid) {
      this.roomNumber_valid = valid;
    },
    onEmailValidation(valid) {
      this.email_valid = valid;
    },
    onCountryUpdate(country) {
      this.country_new = country;
    }
  },
  computed: {
    dataValid() {
      return this.email_valid && this.phone_valid && (this.roomNumber_valid || !this.selectedLocation.roomNumberRequired);
    },
    ...mapGetters(["emailDomain"])
  },
  watch: {
    email: {
      handler(val) {
        this.email_new = val;
      },
    },
    phone: {
      handler(val) {
        this.phone_new = val;
      },
    },
    roomNumber: {
      handler(val) {
        this.roomNumber_new = val;
      },
    },
    country: {
      handler(val) {
        this.country_new = val;
      },
    },
  },
  mounted() {
    this.email_new = this.email;
    this.phone_new = this.phone;
    this.roomNumber_new = this.roomNumber;
    this.country_new = this.country;
  },
};
</script>

<style lang="scss" scoped>
.change-contact-form {
  background: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 16px 16px 0px 0px;
}
.change-contact-form-header {
  padding: 18px 16px 14px 16px;
}
.change-contact-modal-content {
  padding: 16px;
  .spacer {
    min-height: 8px;
  }
}
</style>
