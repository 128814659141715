<template>
  <div @click="onPayClicked" class="native-pay-button-wrapper" :class="buttonStyle">
    <span class="button-text">{{ buttonText }}</span>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: {
    country: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stripe: null,
      stripePublishableKey: null,
      paymentRequest: null,
      nativePaymentMethods: null,
    };
  },
  methods: {
    initPaymentRequest(key) {
      // console.log("initializing payment request");
      this.stripe = Stripe(key);

      /* Creating a payment request */
      this.paymentRequest = this.stripe.paymentRequest({
        country: this.country.code,
        currency: this.country.currencyIsoCode.toLowerCase(),
        total: {
          amount: 0,
          label: "Laundryheap",
          pending: true,
        },
      });

      /* Subscribe to payment event */
      this.paymentRequest.on("paymentmethod", this.onPaid);

      /* Get supported payment method list */
      this.paymentRequest.canMakePayment().then((res) => {
        if (!res) {
          /* Disable the button or something */
        } else {
          // console.log(res);
          this.nativePaymentMethods = res;
        }
      });
    },
    onPaid(event) {
      if (event.paymentMethod && event.paymentMethod.id) {
        this.$emit("paymentMethod", event.paymentMethod);
        event.complete("success");
      } else {
        event.complete("fail");
      }
    },
    onPayClicked() {
      if (this.nativePaymentMethods) {
        this.paymentRequest.show();
      }
    },
  },
  computed: {
    buttonText() {
      if (this.nativeMethod) {
        if (this.nativeMethod == "apple") {
          return "Pay with Pay";
        }
        if (this.nativeMethod == "google") {
          return "Pay with Google Pay";
        }
        if (this.nativeMethod == "other") {
          return "Pay";
        }
        if (this.nativeMethod == "not_supported") {
          return "Not supported, change your payment method";
        }
      }
      return "error";
    },
    nativeMethod() {
      if (this.nativePaymentMethods) {
        if (this.nativePaymentMethods.applePay) {
          return "apple";
        }
        if (this.nativePaymentMethods.googlePay) {
          return "google";
        } else {
          return "other";
        }
      } else {
        return "not_supported";
      }
    },
    buttonStyle() {
      return {
        apple: this.nativeMethod == "apple",
        google: this.nativeMethod == "google",
        other: this.nativeMethod == "other",
        "not-supported": this.nativeMethod == "not_supported",
        disabled: this.disabled
      };
    },
  },
  apollo: {
    stripePublishableKey: {
      query: gql`
        query stripePublishableKey($serviceCountry: ServiceCountryEnum!) {
          stripePublishableKey(serviceCountry: $serviceCountry)
        }
      `,
      variables() {
        return {
          serviceCountry: this.country.code,
        };
      },
    },
  },
  watch: {
    stripePublishableKey: {
      handler(key) {
        if (!this.stripe && key) {
          this.initPaymentRequest(key);
        }
      },
    },
  },
  mounted() {},
  /* Need to get stripe key from location service country and create a payment request */
};
</script>

<style lang="scss" scoped>
.native-pay-button-wrapper {
  min-height: 54px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    text-align: center;
    color: #ffffff;
  }
  &.apple {
    background: #2e2e3a;
    span {
      font-family: -apple-system, 'BlinkMacSystemFont';
    }
  }
  &.google {
    background: #12d572;
  }
  &.other {
    background: #12d572;
  }
  &.not-supported {
    background: #a6a6a6;
  }
  &.disabled {
    background: #a6a6a6;
  }
}
</style>
