<template>
  <div class="pricelist-container">
    <div class="pricelist-modal-header">
      <span class="pricelist-modal-title"
        >{{ selected_price_services.title }} prices</span
      >
      <div class="close-button-container" @click="closeModal">
        <inline-svg 
          :src="require('@/assets/image/x.svg')" 
          fill="black"
        />
      </div>
    </div>
    <div class="fact-check-container">
      <img
        src="@/assets/image/checkmark.svg"
        class="img-no-hidden-fees"
      />
      <div class="fact-check-text-container">
        <span class="fact-check-text-title">No hidden fees</span>
        <span class="fact-check-text-subtitle"
          >No minimum order · No prepayment · Free collection and delivery</span
        >
      </div>
    </div>
    <div v-if="groups && groups.length > 1" class="service-groups-container">
      <span
        v-for="group in groups"
        :key="group.id"
        :class="{ active: selectedpriceGroup && group.id == selectedpriceGroup.id }"
        @click="onGroupChanged(group)"
      >
        {{ group.title }}
      </span>
    </div>
    <div
      class="items"
    >
      <price-list-item
        v-for="item in items"
        :key="item.id"
        :priceItem="item"
        :currency="currency"
        @add="addToEstimator(item)"
      />
    </div>
    <div class="cant-find-item-wrapper">
      <cant-find-item></cant-find-item>
    </div>
  </div>
</template>

<script>
import PriceListItem from "./PriceListItem.vue";
import ContactUsBox from "../ContactUsBox.vue";
import CantFindItem from './../../components/lh-ui/CantFindItem.vue';

import gql from "graphql-tag";

export default {
  props: {
    value: {
      type: Boolean,
    },
    service: {
      type: Object,
    },
  },
  components: {
    PriceListItem,
    ContactUsBox,
    CantFindItem
  },
  data() {
    return {
      show_content: true,
      priceServices: [],
      selected_price_services: [],
      selectedpriceGroup: null,
    };
  },
  computed: {
    groups() {
      return this.selected_price_services?.priceGroups;
    },
    currency() {
      return this.$store.state.location.serviceCountry.currency;
    },
    countryCode() {
      return this.$store.state.location.serviceCountry.code;
    },
    items() {
      return this.selectedpriceGroup?.priceItems  
    }
  },
  apollo: {
    priceServices: {
      query: gql`
        query priceServices($serviceCountry: ServiceCountryEnum) {
          priceServices(serviceCountry: $serviceCountry) {
            active
            currency
            description
            id
            image {
              big
              small
              thumb
            }
            priceServiceInfo {
              title
              description {
                description
                items
                title
              }
              icon
              washTags
            }
            service {
              id
              priceInfo
              pricePerUnit
              tags
              items {
                priceCents
              }
            }
            serviceShortCode
            backgroundColor
            mobileIcon {
              small
              big
            }
            position
            priceGroups {
              id
              image {
                big
                small
                thumb
              }
              position
              priceItems {
                active
                description
                information
                id
                itemWeight
                image {
                  big
                  small
                  thumb
                }
                info
                position
                price
                priceCents
                subtitle
                title
                popularPriceGroup
                popularPriceGroupPosition
                additionalDescription
                existInBundle
                dealQty
                dealItem {
                  id
                  priceCents
                }
                bundles {
                  id
                  name
                  shortCode
                  costCents
                  priceCents
                  priceList
                  information
                  expMonths
                  bundlePriceCentsPerItem
                  bundlePricePerItemLabel
                  fullPriceCentsPerItem
                  fullPricePerItemLabel
                  mobileBundleIcon
                  image {
                    big
                    small
                    thumb
                  }
                }
              }
              title
            }
            priceSections {
              active
              id
              position
              priceGroups {
                id
                image {
                  big
                  small
                  thumb
                }
                position
                priceItems {
                  active
                  description
                  information
                  id
                  image {
                    big
                    small
                    thumb
                  }
                  info
                  position
                  price
                  priceCents
                  subtitle
                  title
                  popularPriceGroup
                  popularPriceGroupPosition
                  additionalDescription
                  existInBundle
                  dealQty
                  dealItem {
                    id
                    priceCents
                  }
                  bundles {
                    id
                    name
                    shortCode
                    costCents
                    priceCents
                    priceList
                    information
                    expMonths
                    shortName
                    bundlePriceCentsPerItem
                    bundlePricePerItemLabel
                    fullPriceCentsPerItem
                    mobileBundleIcon
                    fullPricePerItemLabel
                    image {
                      big
                      small
                      thumb
                    }
                  }
                }
                title
              }
              title
            }
            safeIncludes {
              id
              position
              safe
              title
            }
            serviceCountry
            slug
            subtitle
            title
            unsafeIncludes {
              id
              position
              safe
              title
            }
          }
        }
      `,
      variables() {
        return {
          serviceCountry: this.countryCode,
        };
      },
    },
  },
  watch: {
    priceServices(val) {
      this.onPreselectItems(val)
    },
    service() {
      this.onPreselectItems(this.priceServices)
    },
  },
  methods: {
    onPreselectItems(val) {
      if (val && this.service) {
        this.selected_price_services = val.find(
          (el) =>
            el.title.toLowerCase() === this.service.lightTitle.toLowerCase()
        );
        this.selectedpriceGroup = this.selected_price_services.priceGroups[0];
      }
    },
    onGroupChanged(group) {
      this.selectedpriceGroup = group;
    },
    closeModal() {
      this.show_content = false;
      this.$emit("close");
    },
    keyCloseModal({ key }) {
      if (key === "Escape") {
        this.closeModal();
      }
    },
  },
  mounted() {
    document.addEventListener("backbutton", this.closeModal, false);
    document.addEventListener("keyup", this.keyCloseModal);
  },
  beforeDestroy() {
    document.removeEventListener("backbutton", this.closeModal);
    document.removeEventListener("keyup", this.keyCloseModal);
  },
};
</script>

<style lang="scss" scoped>
.pricelist-container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  -webkit-box-flex: 1;
}
.fact-check-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 26px 16px;
  .fact-check-text-container {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .fact-check-text-title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.2px;
      color: #2e2e3a;
    }
    .fact-check-text-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #a6a6a6;
    }
  }
  .img-no-hidden-fees {
    height: 50px;
    width: 50px;
    display: block;
  }
}
.pricelist-modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.pricelist-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}
.items {
  padding: 0px 16px;
}

.service-groups-container {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  font-size: 13px;
  line-height: 24px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  span {
    padding: 4px 12px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;

    &.active {
      background-color: #1C2026;
      border-radius: 50px;
      color: white;
    }
  }
}
.cant-find-item-wrapper {
  margin: 16px;
}
</style>
