<template>
  <div class="missed-round-prompt-container">
    <div class="missed-round-content">
      <span class="header-h1 title">You just missed today’s available window</span>
      <span class="body-base subtitle"
        >You’ll get your clean items on the:</span
      >
      <div class="timeslot-box">
        <span class="body-base">{{ dropBy | timeString }}</span>
      </div>
    </div>
    <bottom-double-button
      mainText="Ok, proceed"
      altText="No, go back"
      @mainClicked="proceedClicked"
      @altClicked="cancelClicked"
    >
    </bottom-double-button>
  </div>
</template>

<script>
import BottomDoubleButton from "../lh-ui/BottomDoubleButton.vue";
export default {
  components: {
    BottomDoubleButton,
  },
  props: {
    location: {
      type: Object,
    },
  },
  methods: {
    proceedClicked() {
      this.$emit("proceed");
    },
    cancelClicked() {
      this.$emit("cancel");
    },
  },
  computed: {
    dropBy(){
      return this.$store.getters.collectAfter;
    }
  }
};
</script>

<style lang="scss" scoped>
.missed-round-prompt-container {
  background: #ffffff;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 16px 16px 0px 0px;
  flex: 1;
  .missed-round-content {
    padding: 32px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      text-align: center;
    }
    .title {
      margin-bottom: 16px;
    }
    .subtitle {
      margin-bottom: 8px;
    }
    .timeslot-box {
      min-height: 44px;
      min-width: 135px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px solid #dce1e5;
      border-radius: 2px;
      padding: 0px 16px;
    }
  }
}
</style>
