<template>
  <page-container page="location" headerText="1 - Location">
    <template slot="append-header">
      <img
        v-if="!show_search_field"
        @click="searchIconClicked"
        class="search-button-image"
        src="@/assets/image/location/search_button.svg"
        alt=""
      />
    </template>
    <div class="search-field-container" v-if="show_search_field">
      <search-field
        placeholder="Search for your location"
        v-model="search_query"
        @focusChanged="onSearchFieldFocusChanged"
      ></search-field>
    </div>
    <div class="location-list-filler" v-if="!lightLocations"></div>
    <transition-group name="list" tag="div">
      <location-list-item
        v-for="(loc, index) in lightLocations"
        :key="loc.id"
        :location="loc"
        :expanded="index == 0 && !show_search_field"
        @locationClicked="locationClicked(loc)"
      >
      </location-list-item>
    </transition-group>
    <bottom-modal v-model="show_dialog" lightMode>
      <location-details
        :location="selected_location"
        @locationConfirmed="locationConfirmed"
      >
      </location-details>
    </bottom-modal>
  </page-container>
</template>

<script>
import PageContainer from "../components/PageContainer";
import LocationListItem from "../components/location/LocationListItem";
import LocationDetails from "../components/location/LocationDetails";
import SearchField from "../components/lh-ui/SearchField.vue";
import gql from "graphql-tag";
import {mapGetters} from "vuex";

export default {
  components: { PageContainer, LocationListItem, LocationDetails, SearchField },
  data: function () {
    return {
      show_dialog: false,
      selected_location: null,
      lightLocations: [],
      /* Search */
      show_search_field: false,
      search_query: null,
      search_field_focused: false,
      /* Location sorting stuff */
      lat: null,
      lng: null,
      must_sort: false,
    };
  },
  apollo: {
    lightLocations: {
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          lightLocations {
            id
            name
            url
            lat
            lng
            address
            promo
            laundryheapPoint
            roomNumberRequired
            firstImage
            secondImage
            dropoffBefore
            pickupAfter
            serviceCountry {
              id
              code
              currency
              currencyIsoCode
              timezone
              url
            }
            logo
            emailDomain
            invoiceManually
          }
        }
      `,
    },
  },
  computed: {
    tagCode() {
      return this.$store.state.tag_code;
    },
  },
  watch: {
    lightLocations: {
      deep: true,
      handler() {
        if (this.must_sort) {
          this.sortByLocation();
        }
      },
    },
    search_query: {
      handler(val) {
        if (!val) {
          return;
        }
        let others = [];
        let matches = this.lightLocations.filter((loc) => {
          if (
            loc.name.toLowerCase().includes(val.toLowerCase()) ||
            loc.address.toLowerCase().includes(val.toLowerCase())
          ) {
            return true;
          } else {
            others.push(loc);
            return false;
          }
        });
        this.lightLocations = [...matches, ...others];
      },
    },
    /* When modal is open, disable background scrolling */
    show_dialog: {
      handler(val) {
        if (val) {
          document.documentElement.style.overflow = "hidden";
        } else {
          document.documentElement.style.overflow = "auto";
        }
      },
    },
  },
  methods: {
    locationRetreived({ coords }) {
      this.lat = coords.latitude;
      this.lng = coords.longitude;
      this.must_sort = true;
      this.sortByLocation();
    },
    locationDenied() {
      // use ip location
    },
    locationClicked(location) {
      this.selected_location = location;
      this.show_dialog = true;
    },
    locationConfirmed(location) {
      document.documentElement.style.overflow = "auto";
      this.$store.dispatch("saveLocation", location);
      this.$router.push({ name: "Services" });
    },
    searchIconClicked() {
      this.show_search_field = true;
      // console.log("search clicked");
    },
    sortByLocation() {
      if (this.lightLocations.length < 1) {
        // console.log("sort: no locations yet");
        return;
      }
      // console.log("sorting locations by proximity");
      const my_lat = this.lat;
      const my_lng = this.lng;

      let arrWithDistances = []

      for (const loc of this.lightLocations) {
        const lat = loc.lat;
        const lng = loc.lng;

        const distance = Math.sqrt(
          Math.pow(my_lat - lat, 2) + Math.pow(my_lng - lng, 2)
        );

        const newLoc = Object.assign({},loc, { distance: distance });
        arrWithDistances.push(newLoc)
      }
      arrWithDistances.sort((a, b) => {
        return a.distance - b.distance;
      });

      this.lightLocations = arrWithDistances

      this.must_sort = false;
    },
    onSearchFieldFocusChanged(focused) {
      this.search_field_focused = focused;
    },
  },
  created() {
    this.$store.dispatch('saveLocation',{})
  },
  mounted() {
    try {
      navigator.geolocation.getCurrentPosition(
        this.locationRetreived,
        this.locationDenied
      );
    } catch (error) {}
  },
};
</script>

<style lang="scss" scoped>
.search-button-image {
  display: block;
  height: 40px;
  width: 40px;
}
.search-field-container {
  margin-bottom: 16px;
}
.location-list-filler {
  height: 100vh;
}
.step-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.step-header {
  margin: 20px 0px 0px 0px;
}
.search-button {
  cursor: pointer;
}
.search-field {
  margin-top: 15px;
}
.align-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
/* List animation */
.list-move {
  transition: transform 200ms ease-in-out;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 300ms ease-in-out;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  // transform: translateY(30px);
}
</style>
